/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import { observer } from "mobx-react";
import { validate as isValidUUID } from "uuid";
import styles from "~views/pages/CustomerCompliancePage/CustomerCompliancePage.scss";
import rootStyles from "~views/pages/Root.scss";
import {
  ComplianceFieldType,
  IComplianceDefinition,
  IComplianceDefinitionQuestion,
  IComplianceFieldOption,
} from "netbank-shared/src/libs/models/CustomerCompliance/Compliance";
import { RadioButtons } from "~views/shared/RadioButtons/RadioButtons";
import { MultiSelect } from "~views/shared/MultiSelect/MultiSelect";
import { Dropdown, ErrorText, Input } from "~views/shared";
import { TextArea } from "~views/shared/TextArea/TextArea";
import { Checkbox } from "~views/shared/Checkbox/Checkbox";
import DayPicker, { NavbarElementProps } from "react-day-picker";
import { Navbar } from "~views/shared/DateRange/Navbar";
import { dateLocaleUtils } from "netbank-shared/src/libs/utils/dateLocal";
import { useStores } from "netbank-shared/src/hooks";
import { IMultiSelectOption } from "netbank-shared/src/libs/models/MultiSelect/MultiSelectOption";
import { ListItem } from "netbank-shared/src/libs/models/Content/ListItem";
import { tx } from "netbank-shared/src/libs/i18n";
import { ICustomerCompliancePage } from "netbank-shared/src/libs/models/Content/Page";
import { FileAttachement } from "~views/shared/FileAttachment/FileAttachment";
import { IAttachment } from "netbank-shared/src/libs/models/Attachment/Attachment";
import { OrganizationOwnerTable } from "./OrganizationOwnerTable/OrganizationOwnerTable";

interface ICustomerComplianceFormProps {
  template: ICustomerCompliancePage;
}

export const CustomerComplianceForm = observer(({ template }: ICustomerComplianceFormProps) => {
  const { rootStore, uiStore, complianceStore } = useStores();

  const {
    currentStep,
    complianceDefinition,
    currentErrors,
    showValidationErrors,
    acceptedDocumentMimeTypes,
    acceptedDocumentExtensions,
  } = complianceStore;

  const isContentField = (field: IComplianceDefinitionQuestion) =>
    [ComplianceFieldType.paragraph, ComplianceFieldType.h3, ComplianceFieldType.h4, ComplianceFieldType.h5].includes(
      field.type
    );

  const renderField = (answerId: string, field: IComplianceDefinitionQuestion) => {
    const { id, type, questionText: label, questionHelpText: helpText, options, editable, children } = field;

    const currentQuestion: IComplianceDefinitionQuestion | undefined = complianceStore.getQuestionById(id);
    const answers = currentQuestion?.answers;

    const error: string | undefined = showValidationErrors ? currentErrors?.[id]?.[0] : undefined;

    switch (type) {
      case ComplianceFieldType.h3:
        return <h3>{field.questionText}</h3>;
      case ComplianceFieldType.h4:
        return <h4>{field.questionText}</h4>;
      case ComplianceFieldType.h5:
        return <h5>{field.questionText}</h5>;
      case ComplianceFieldType.paragraph:
        return <p>{field.questionText}</p>;
      case ComplianceFieldType.TextField:
        return (
          <Input
            label={label}
            value={answers?.[0]?.text}
            onChange={(e) => {
              const updatedAnswers = [{ ...answers?.[0], text: e.target.value, id: e.target.value }];
              complianceStore.updateField(id, updatedAnswers);
            }}
            infoPopover={{
              popoverTitle: tx("misc.information"),
              content: helpText,
            }}
            disabled={complianceStore.updating || !editable}
            error={error}
          />
        );
      case ComplianceFieldType.PositiveIntegerInput:
        return (
          <Input
            label={label}
            value={answers?.[0]?.text}
            onChange={(e) => {
              const val = Number(e.target.value.replace(/\D/g, ""));
              if (!Number.isNaN(val)) {
                const parsedInteger = Math.round(val);
                const updatedAnswers = [
                  { ...answers?.[0], text: parsedInteger.toString(), id: parsedInteger.toString() },
                ];
                complianceStore.updateField(id, updatedAnswers);
              }
            }}
            infoPopover={{
              popoverTitle: tx("misc.information"),
              content: helpText,
            }}
            disabled={complianceStore.updating || !editable}
            error={error}
          />
        );
      case ComplianceFieldType.DecimalInput:
        return (
          <Input
            label={label}
            value={answers?.[0]?.text}
            onChange={(e) => {
              const val = e.target.value.replace(/[^\d.]/g, "");
              if (!Number.isNaN(Number(val))) {
                const updatedAnswers = [{ ...answers?.[0], text: val, id: val }];
                complianceStore.updateField(id, updatedAnswers);
              }
            }}
            infoPopover={{
              popoverTitle: tx("misc.information"),
              content: helpText,
            }}
            disabled={complianceStore.updating || !editable}
            error={error}
          />
        );
      case ComplianceFieldType.TextArea:
        return (
          <TextArea
            label={label}
            value={answers?.[0]?.text}
            onChange={(e) => {
              const updatedAnswers = [{ ...answers?.[0], text: e.target.value, id: e.target.value }];
              complianceStore.updateField(id, updatedAnswers);
            }}
            infoPopover={{
              popoverTitle: tx("misc.information"),
              content: helpText,
            }}
            disabled={complianceStore.updating || !editable}
            error={error}
          />
        );
      case ComplianceFieldType.CheckBox:
        return (
          <Checkbox
            label={label}
            checkboxId={id}
            checked={answers?.[0]?.text === "True"}
            onChange={(e) => {
              const val = e.target.checked ? "True" : "False";
              const updatedAnswers = [{ id: val, text: val }];
              complianceStore.updateField(id, updatedAnswers);
            }}
            disabled={complianceStore.updating || !editable}
            error={error}
          />
        );
      case ComplianceFieldType.RadioButtons:
      case ComplianceFieldType.RadioButtonsYesNo:
        return (
          <RadioButtons
            label={label}
            list={
              options
                ? options.map(
                    (o, i) =>
                      ({
                        label: o.text,
                        value: o.text,
                        index: i,
                      } as ListItem)
                  )
                : []
            }
            value={answers?.[0]?.text}
            onChange={(item) => {
              const option = options?.find((opt) => opt.text === item.value);
              const updatedAnswers = [{ ...answers?.[0], text: item.value, id: option?.id }];
              complianceStore.updateField(id, updatedAnswers);
            }}
            infoPopover={{
              popoverTitle: tx("misc.information"),
              content: helpText,
            }}
            disabled={complianceStore.updating || !editable}
            error={error}
          />
        );
      case ComplianceFieldType.DropDownList:
      case ComplianceFieldType.DropDownListYesNo:
        return (
          <Dropdown
            label={label}
            list={
              options
                ? options.map(
                    (o, i) =>
                      ({
                        label: o.text,
                        value: o.text,
                        index: i,
                      } as ListItem)
                  )
                : []
            }
            value={answers?.[0]?.text}
            onChange={(item) => {
              const option = options?.find((opt) => opt.text === item.value);
              const updatedAnswers = [{ ...answers?.[0], text: item.value, id: option?.id }];
              complianceStore.updateField(id, updatedAnswers);
            }}
            infoPopover={{
              popoverTitle: tx("misc.information"),
              content: helpText,
            }}
            disabled={complianceStore.updating || !editable}
            error={error}
          />
        );
      case ComplianceFieldType.DropDownListMulti:
        return (
          <MultiSelect
            label={label}
            options={options?.map(
              (o, i) =>
                ({
                  label: o.text,
                  value: o.text,
                  index: i,
                } as IMultiSelectOption)
            )}
            value={
              answers &&
              answers
                .filter((a) => !!a.text)
                .map(
                  (o, i) =>
                    ({
                      label: o.text,
                      value: o.text,
                      index: i,
                    } as IMultiSelectOption)
                )
            }
            onChange={(value) => {
              const updatedAnswers =
                value?.map(
                  (v) =>
                    ({
                      text: (v as any).value,
                      id: options?.find((opt) => opt.text === (v as any).value)?.id,
                    } as IComplianceFieldOption)
                ) || [];
              complianceStore.updateField(id, updatedAnswers);
            }}
            infoPopover={{
              popoverTitle: tx("misc.information"),
              content: helpText,
            }}
            placeholder={tx("misc.chooseCitizenshipPlaceholder")}
            disabled={complianceStore.updating || !editable}
            error={error}
            showMenuOnFocus
          />
        );
      case ComplianceFieldType.DatePicker:
        return editable ? (
          <>
            <span className={rootStyles.label}>{label}</span>
            <DayPicker
              navbarElement={({ ...props }: NavbarElementProps) => <Navbar {...props} />}
              captionElement={() => null}
              onDayClick={(date: Date) => {
                if (complianceStore.updating) return;

                const updatedAnswers = [{ ...answers?.[0], text: date?.toString(), id: date?.toString() }];
                complianceStore.updateField(id, updatedAnswers);
              }}
              initialMonth={answers?.[0]?.text ? new Date(answers[0].text) : undefined}
              selectedDays={answers?.[0]?.text ? new Date(answers[0].text) : undefined}
              localeUtils={dateLocaleUtils}
              locale={uiStore.lang}
            />
          </>
        ) : null;
      case ComplianceFieldType.FileUploader:
        return (
          <FileAttachement
            id={id}
            attachments={answers?.map(
              (answer) =>
                ({
                  id: answer.text && isValidUUID(answer.text) ? answer.text : answer.id,
                  name: answer.id,
                  size: answer.fileSize,
                } as IAttachment)
            )}
            addAttachments={(attachments) => complianceStore.uploadAttachments(answerId, id, attachments)}
            removeAttachment={(fileId) => complianceStore.deleteAttachment(answerId, id, fileId)}
            acceptedMimeTypes={acceptedDocumentMimeTypes}
            acceptedExtensions={acceptedDocumentExtensions}
            maxAttachments={25}
            label={label}
            infoPopover={{
              popoverTitle: tx("misc.information"),
              content: helpText,
            }}
            desktopLabel={template.fileUploaderDesktopLabel}
            desktopLabelSuffix={template.fileUploaderDesktopLabelSuffix}
            mobileLabel={template.fileUploaderMobileLabel}
            maxFileSizeLabel={template.fileUploaderMaxFileSizeLabel}
            acceptedExtensionsLabel={template.fileUploaderAcceptedExtensionsLabel}
            disabled={complianceStore.updating || !editable}
            errors={showValidationErrors ? currentErrors?.[id] : undefined}
            multiple
          />
        );
      case ComplianceFieldType.BeneficialOwnerTable:
      case ComplianceFieldType.CompanyOwnerTable: {
        const list = children
          ?.find((c) => c.type === ComplianceFieldType.tbody)
          ?.children?.filter((c) => c.type === ComplianceFieldType.tr && c.children)
          ?.map(
            (c) =>
              c.children
                ?.filter((i) => i.type === ComplianceFieldType.td && !!i.children?.[0])
                ?.map((i) => i.children![0])!
          );

        return (
          <OrganizationOwnerTable
            id={id}
            label={label}
            list={list}
            infoPopover={{
              popoverTitle: tx("misc.information"),
              content: helpText,
            }}
          />
        );
      }
      case ComplianceFieldType.Conditional: {
        const isValid = complianceStore.validateConditionalsByQuestion(field);

        return isValid && field.children ? renderQuestions(answerId, field.children) : null;
      }
      default:
        return null;
    }
  };

  const renderQuestions = (answerId: string, questions: IComplianceDefinitionQuestion[]) => {
    return questions.map((f) => {
      const classes = [styles.field];

      if (!isContentField(f) && f.type !== ComplianceFieldType.Conditional) {
        classes.push(styles.editable);
      }

      return (
        <div className={classes.join(" ")} key={f.id}>
          {renderField(answerId, f)}
        </div>
      );
    });
  };

  const step: IComplianceDefinition | undefined = complianceDefinition?.[currentStep];

  if (!step) return null;

  const account = step.accountId && rootStore.getAccountByComplianceAccountId(step.accountId);

  return (
    <>
      {(account || step.title) && <h1>{account ? account.nameIB : step.title}</h1>}
      {step.accountId && (
        <p>
          <span className={rootStyles.bold}>{`${tx("misc.accountNumber")}: `}</span>
          {` ${account ? account.displayNumber : step.accountId}`}
        </p>
      )}
      {renderQuestions(step.answerId, step.questions)}
      {complianceStore.showValidationErrors && (
        <ErrorText error={template?.validationErrorText || tx("compliance.validationErrorText")} />
      )}
      {complianceStore.showUnexpectedError && (
        <ErrorText error={template?.unexpectedErrorText || tx("compliance.unexpectedErrorText")} />
      )}
    </>
  );
});
