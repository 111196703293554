import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import { observer } from "mobx-react";
import React, { RefObject, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Input, Lottie, ExpandableText } from "~views/shared/index";
import { useHistory } from "react-router-dom";
import QRCode from "qrcode.react";
import { useStores, useTracking } from "netbank-shared/src/hooks";
import { tx } from "netbank-shared/src/libs/i18n";
import { IFAQItem } from "netbank-shared/src/libs/models/Content/accordions/FAQItem";
import { IGenericContentLink } from "netbank-shared/src/libs/models/Content/ContentLink";
import { BankIdSignStatus, Environment, Lang } from "netbank-shared/src/libs/models/Content/Enums";
import { Store } from "netbank-shared/src/stores";
import { isUrl } from "netbank-shared/src/libs/utils";
import reactStringReplace from "react-string-replace";
import bankId from "~assets/bankId-large.svg";
import bankIdBlacKWhite from "~assets/bankid-black-white.svg";

import handCounting from "~assets/lottie/hand_counting.json";
import loadingAnimationData from "~assets/lottie/loading.json";
import { withStore } from "../../../mobx-web";
import { ConfirmationPopup } from "~views/shared/ConfirmationPopup/ConfirmationPopup";
import { HtmlContent } from "~views/shared/HtmlContent/HtmlContent";
import { InputToggle } from "~views/shared/InputToggle/InputToggle";
import styles from "./Login.scss";
import { CookieConsent } from "~views/shared/CookieConsent/CookieConsent";
import smallComp from "~assets/small-computer.svg";
import smallMobile from "~assets/small-mobile.svg";
import chevronRight from "~assets/chevron-right-red.svg";
import qrCodeIcon from "~assets/qr-code.svg";
import santanderLogotype from "~assets/santander-logo.svg";
import { LoginNotifications } from "~views/shared/LoginNotifications/LoginNotifications";
import { TrackingAction, TrackingCategory } from "netbank-shared/src/libs/models/Tracking";
import { RetryPage } from "../RetryPage/RetryPage";
import { IPage } from "netbank-shared/src/libs/models/Content/Page";
import { ConfigEditor } from "~views/shared/ConfigEditor/ConfigEditor";
import { ProfilePickerPage } from "./ProfilePickerPage";
import { ImageQrBlock } from "./ImageQrBlock";

export interface ILoginPageProps {
  location: Location;
  store: Store;
}

export interface ILoginPageFaqItemProps {
  item: IGenericContentLink<IFAQItem>;
}

const LoginPageFaqItem = observer(({ item }: ILoginPageFaqItemProps) => {
  const guid = item.contentLink.guidValue;
  const [open, setOpen] = useState(false);
  const questionClasses = [styles.question];
  const answerClasses = [styles.answer];
  if (open) {
    questionClasses.push(styles.open);
    answerClasses.push(styles.open);
  }
  return (
    <div className={styles.qaWrapper}>
      <div key={`faq-${guid}`} className={questionClasses.join(" ")} onClick={() => setOpen(!open)}>
        <span>{item.question}</span>
      </div>
      <HtmlContent className={answerClasses.join(" ")} html={item.answer} />
    </div>
  );
});

const LoginPendingView = observer(() => {
  const { contentStore, uiStore, customerStore, rootStore, bankIdSignStore } = useStores();
  const { currentPage } = contentStore;
  const { isMobile } = uiStore;

  const verifyingHeader = currentPage?.loginVerifyingHeader;
  const verifyingTextMobile = currentPage?.loginVerifyingTextMobile || tx("login.verifyingTextMobile");
  const verifyingTextDesktop = currentPage?.loginVerifyingText || tx("login.verifyingTextDesktop");

  const verifyingText = uiStore.isMobile ? verifyingTextMobile : verifyingTextDesktop;
  const openBankIdButtonText = currentPage?.openBankIdButtonTitle;

  const offset = 170;
  const numOfLoginNotificaitions = currentPage?.loginNotifications?.length || 0;
  const notificationPaddingOffset = numOfLoginNotificaitions ? numOfLoginNotificaitions * offset : 0;
  return (
    <div
      className={`${styles.contentWrapper} ${styles.pendingWrapper} ${styles.flex1} ${styles.flexCol}`}
      style={{ paddingTop: notificationPaddingOffset }}
    >
      <div>
        {verifyingHeader && <h1>{verifyingHeader}</h1>}
        {verifyingText && (
          <p className={styles.verifyingText}>
            {reactStringReplace(verifyingText, tx("login.bankIDLinkName"), () => (
              <a href={rootStore.bankIdUrl(bankIdSignStore.bankIdAutostartToken)}>
                {tx("login.bankIDLinkName")}
              </a>
            ))}
          </p>
        )}
      </div>
      <Lottie
        style={{ display: "block", margin: 0 }}
        options={{
          animationData: handCounting,
        }}
        height={isMobile ? 149 : 238}
        width={isMobile ? "auto" : 310}
      />
      <div>
        {isMobile && (
          <Button
            title={openBankIdButtonText}
            color="red"
            iconPrefix={bankIdBlacKWhite}
            className={styles.bankIdButton}
            fullWidth
            centered
            onClick={() => rootStore.openBankId(bankIdSignStore.bankIdAutostartToken)}
          />
        )}
        <div
          className={styles.cancel}
          onClick={() => {
            customerStore.cancelLogin();
          }}
        >
          {tx("misc.cancel")}
        </div>
      </div>
    </div>
  );
});

const LoginView = observer(() => {
  const { bankIdSignStore, contentStore, uiStore, customerStore } = useStores();
  const { currentPage } = contentStore;

  const qrFAQ = currentPage?.qrFAQ;
  const loginQRTitle = currentPage?.loginQRTitle || tx("login.loginQRTitle");
  const qrStepGuide = currentPage?.stepGuideText;

  const offset = 170;
  const numOfLoginNotificaitions = currentPage?.loginNotifications?.length || 0;
  const notificationPaddingOffset = numOfLoginNotificaitions ? numOfLoginNotificaitions * offset : 0;
  const renderQR = () => {
    return (
      <div
        className={`${styles.contentWrapper} ${styles.pendingWrapper} ${styles.flex1} ${styles.flexCol}`}
        style={{ paddingTop: notificationPaddingOffset }}
      >
        {bankIdSignStore.qrCode !== "" && (
          <>
            <h1 className={styles.qrTitle}>{loginQRTitle}</h1>
            <div style={{ order: uiStore.isMobile ? 1 : 2 }}>
              <div className={`${styles.qrContainer} ${styles.flexCenter}`} style={{ order: uiStore.isMobile ? 1 : 2 }}>
                <ImageQrBlock token={bankIdSignStore.qrCode} isMobile={uiStore.isMobile} />
              </div>
            </div>
            <div
              className={`${styles.cancel} ${styles.flexCenter}`}
              style={{ order: uiStore.isMobile ? 2 : 3 }}
              onClick={() => {
                customerStore.cancelLogin();
              }}
            >
              {tx("misc.cancel")}
            </div>
            <div className={styles.stepGuide} style={{ order: uiStore.isMobile ? 2 : 1 }}>
              {qrStepGuide && <HtmlContent html={qrStepGuide} className={styles.stepGuide} />}
            </div>

            {qrFAQ && !uiStore.isMobile && (
              <div className={styles.qrViewFaq} style={{ order: 3 }}>
                <hr />
                {qrFAQ.map((item) => (
                  <LoginPageFaqItem item={item} key={item.contentLink.guidValue} />
                ))}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  return customerStore.loginOnSameDevice ? <LoginPendingView /> : renderQR();
});

const LoginPage = observer(({ location, store }: ILoginPageProps) => {
  const confirmationPopupRef: RefObject<HTMLDivElement> = useRef(null);
  const {
    customerStore,
    uiStore,
    contentStore,
    rootStore,
    globalPersistentStore,
    securePersistentStore,
    customerPersistentStore,
    bankIdSignStore
  } = useStores();


  const [uncachedHomePage, setUncachedHomePage] = useState<IPage>();
  const history = useHistory();

  const dataLayer = useTracking((window as any).dataLayer);

  const { currentPage } = contentStore;

  const logotype = uiStore.isMobile
    ? currentPage?.mobileLogotype?.url
    : currentPage?.logotypeLoginPage?.url || santanderLogotype;

  const santanderHomepageURL = currentPage?.santanderHomepageURL || tx("login.santanderHomepageURL");

  const loginHeader = currentPage?.loginHeader;
  const loginText = currentPage?.loginText;
  const loginButtonTextMobile = currentPage?.loginButtonTextMobile;
  const loginButtonTextDesktop = currentPage?.loginButtonTextDesktop;

  const buttonImageMobile = currentPage?.buttonImageMobile?.url;
  const buttonImageDesktop = currentPage?.buttonImageDesktop?.url;

  const loginFAQ = currentPage?.loginFAQ;

  const loginImage = uiStore.isMobile ? currentPage?.loginImageMobile : currentPage?.loginImage;

  const confirmationPopupActive = uiStore.confirmationPopup !== undefined;

  const offset = 170;
  const numOfLoginNotificaitions = currentPage?.loginNotifications?.length || 0;
  const notificationPaddingOffset = numOfLoginNotificaitions ? numOfLoginNotificaitions * offset : 0;

  const handleLoginError = () => {
    // Removes hash set by bankid redirect to ensure safari does not refresh page.
    // If the hash already exists when trying again, the customer will be stuck in an infinite loop
    // where the page refreshes after coming back from bankid
    rootStore.removeBankIdHash(`${tx("routing.lang")}/login`);
  };

  const handleLoginSuccess = async () => {
    const customer = await customerStore.getCustomer();

    customerPersistentStore.setLoginOnSameDevice(customerStore.loginOnSameDevice);

    if (!customer && customerStore.loginResubmitForm) {
      customerStore.resetLoginState();
      rootStore.setNoCustomerError(customerStore.loginResubmitForm);
      handleLoginError();
      return;
    }

    if (customerStore.hasParties) return;

    if (!customerPersistentStore.viewedTerms && contentStore.currentPage?.termsPage) {
      history.push(contentStore.currentPage.termsPage);
      dataLayer.pushPageView(contentStore.currentPage.termsPage);
    } else {
      history.push(`/${tx("routing.lang")}`);
      dataLayer.pushPageView(`/${tx("routing.lang")}`);
    }
  };

  const onSubmit = async () => {
    // Persist event for resubmisison on error in confirmation modal.
    await customerStore.login(onSubmit, handleLoginSuccess);
  };

  useEffect(() => {
    // If history action is redirect from bankid, return
    const hash = history?.location?.hash;


    if (hash != null && hash.includes("#bankidIdentified")) {
      const nonceRegex = /(?<=&nonce=)[^&]+/;
      const startStateRegex = /startState=(.*)/;
      const nonceMatch = hash.match(nonceRegex);
      const startStateMatch = hash.match(startStateRegex);

      if (nonceMatch && startStateMatch) {
        const nonce = nonceMatch[0];
        const startState = startStateMatch[1];
        customerStore.loginOnSameDevice = true;
        customerStore.redirectFromDifferentBrowserLogin(nonce, startState, onSubmit, handleLoginSuccess);
      }

    }
    else {
      customerStore.cancelLogin();
    }
  }, [history.action]);

  useEffect(() => {
    uiStore.setLoading(true);
    const fetchPage = async () => {
      // Do not cache homepage used to show login content.
      // User specific headers are missing for episerver visitor groups.
      const homePage = await contentStore.getPage(`/${tx("routing.lang")}`, false);
      setUncachedHomePage(homePage);
      const availableLanguages =
        (homePage?.languagePicker?.supportedLanguages?.split(",").filter((s) => s !== "") as Lang[]) || [];

      // Swedish should always be available
      if (!availableLanguages.includes(Lang.sv)) {
        availableLanguages.push(Lang.sv);
      }

      if (!availableLanguages?.includes(tx("routing.lang") as Lang)) {
        contentStore.setI18nLang(Lang.sv);
        rootStore.commonService.redirectToLogin();
      }
    };
    fetchPage();
    if (securePersistentStore.accessToken) {
      rootStore.logout(true);
    }
    uiStore.setLoading(!!contentStore.currentPage);

  }, [location]);

  useEffect(() => {
    if (!currentPage) {
      uiStore.setLoading(true);
    } else {
      uiStore.setLoading(false);
    }
  }, [currentPage]);

  useEffect(() => {
    if (confirmationPopupActive) {
      if (confirmationPopupRef.current) disableBodyScroll(confirmationPopupRef.current);
    } else {
      clearAllBodyScrollLocks();
    }
  }, [confirmationPopupActive]);

  useEffect(() => {
    const onVisibilityChange = () => {
      const { visibilityState } = document;
      if (visibilityState === "hidden") {
        bankIdSignStore.isPaused = true;
      }

      if (uiStore.platformStore.isSafari?.() && visibilityState === "visible") {
        bankIdSignStore.isPaused = false;
      }
    };

    const onFocus = () => {
      bankIdSignStore.isPaused = false;
    };

    window.addEventListener("visibilitychange", onVisibilityChange);
    window.addEventListener("focus", onFocus);

    return () => {
      window.removeEventListener("visibilitychange", onVisibilityChange);
      window.removeEventListener("focus", onFocus);
    }
  }, [uiStore.platformStore, bankIdSignStore])



  if (customerStore.hasParties) {
    return (
      <ProfilePickerPage
        image={loginImage}
        uncachedHomePage={uncachedHomePage}
        data={{
          header: currentPage?.profilePickerHeader || tx("login.profilePickerHeader"),
          subHeader: currentPage?.profilePickerSubHeader || tx("login.profilePickerSubHeader"),
          privateProfileLabel: currentPage?.privateProfileLabel || tx("login.privateProfileLabel"),
          organizationProfileLabel: currentPage?.organizationProfileLabel || tx("login.organizationProfileLabel"),
        }}
      />
    );
  }

  if (contentStore.isRetryPage) return <RetryPage />;

  if (!currentPage) return null;
  return (
    <>
      <Helmet>
        <title>
          {currentPage?.loginPageTitle ? `${currentPage?.loginPageTitle} - Santander` : "Netbank - Santander"}
        </title>
      </Helmet>
      {rootStore.config?.COOKIE_CONSENT_SCRIPT && isUrl(rootStore.config.COOKIE_CONSENT_SCRIPT) && (
        <CookieConsent cookieScript={rootStore.config.COOKIE_CONSENT_SCRIPT} />
      )}
      {confirmationPopupActive && <ConfirmationPopup confirmationPopupRef={confirmationPopupRef} />}
      <header className={`${styles.loginHeader} ${styles.flexBetween}`}>
        {logotype && (
          <a href={santanderHomepageURL}>
            <img className={styles.loginLogotype} src={logotype} alt="Santander" />
          </a>
        )}
      </header>
      <div>
        <LoginNotifications />
        <div className={styles.wrapper}>
          {!uiStore.isMobile && (
            <div className={styles.imageWrapper}>
              <div className={styles.image} style={{ backgroundImage: `url("${loginImage?.url}")` }} />
            </div>
          )}
          {bankIdSignStore.status === BankIdSignStatus.Inactive && !customerStore.fetchingAccessToken && (
            <div
              className={`${styles.contentWrapper} ${styles.form}`}
              style={{ paddingTop: notificationPaddingOffset }}
            >
              <h1>{loginHeader}</h1>
              <p className={styles.large}>{loginText}</p>
              <div className={`${styles.flexCenter} ${styles.logoWrapper}`}>
                <img src={bankId} alt="bankIdLogo" />
              </div>
              <Button
                title={
                  uiStore.isMobile
                    ? loginButtonTextDesktop || tx("login.loginButtonTextDesktop")
                    : loginButtonTextMobile || tx("login.loginButtonTextMobile")
                }
                color="catskillWhite"
                iconPrefix={buttonImageMobile || smallMobile}
                iconSuffix={chevronRight}
                bordered
                borderColor="darkSky"
                className={styles.loginButton}
                fullWidth
                centered
                onClick={() => {
                  if (uiStore.isMobile) {
                    customerStore.loginOnSameDevice = true;
                  } else {
                    customerStore.loginOnSameDevice = false;
                  }
                  onSubmit();
                }}
                trackingCategory={TrackingCategory.Login}
                trackingAction={uiStore.isMobile ? TrackingAction.SameDevice : TrackingAction.AnotherDevice}
              />
              <Button
                title={
                  uiStore.isMobile
                    ? loginButtonTextMobile || tx("login.loginButtonTextMobile")
                    : loginButtonTextDesktop || tx("login.loginButtonTextDesktop")
                }
                color="white"
                iconPrefix={uiStore.isMobile ? qrCodeIcon : buttonImageDesktop || smallComp}
                iconSuffix={chevronRight}
                bordered
                borderColor="darkSky"
                className={styles.loginButton}
                fullWidth
                centered
                onClick={() => {
                  if (uiStore.isMobile) {
                    customerStore.loginOnSameDevice = false;
                  } else {
                    customerStore.loginOnSameDevice = true;
                  }
                  onSubmit();
                }}
                trackingCategory={TrackingCategory.Login}
                trackingAction={uiStore.isMobile ? TrackingAction.AnotherDevice : TrackingAction.SameDevice}
              />
              {store.config?.OCTOPUS_ENV !== Environment.Production && (
                <>
                  <div className={styles.mockToggle}>
                    <span>{tx("login.mockData")}</span>
                    <InputToggle
                      id="mock-toggle"
                      toggle={() => store.setIsMock(!store.isMock)}
                      checked={globalPersistentStore.isMock}
                    />
                  </div>
                  <ConfigEditor />
                  <ExpandableText header="Token login">
                    <Input
                      value={store.securePersistentStore.accessToken}
                      placeholder="access token"
                      onChange={(e) => {
                        store.securePersistentStore.setAccessToken(e.target.value);
                      }}
                      onBlur={() => {
                        if (
                          store.securePersistentStore?.accessToken &&
                          store.securePersistentStore.accessToken.length > 30
                        ) {
                          store.securePersistentStore.setExpiresIn(1000 * 60 * 60);
                          store.securePersistentStore.setTokenTimestamp(new Date().toString());
                          store.securePersistentStore.setRefreshToken("manual refresh");
                          handleLoginSuccess();
                        }
                      }}
                    />
                  </ExpandableText>
                </>
              )}
              <hr />
              {loginFAQ &&
                loginFAQ.length > 0 &&
                loginFAQ.map((item) => <LoginPageFaqItem item={item} key={item.contentLink.guidValue} />)}
            </div>
          )}

          {(bankIdSignStore.status === BankIdSignStatus.Initializing || customerStore.fetchingAccessToken) && (
            <Lottie
              options={{
                animationData: loadingAnimationData,
              }}
              height={100}
              width={100}
            />
          )}

          {bankIdSignStore.status === BankIdSignStatus.Polling && <LoginView />}
        </div>
      </div>
    </>
  );
});

export default withStore(LoginPage);
