import React from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { ILoanTopUpPage } from "netbank-shared/src/libs/models/Content/Page";
import { useStores } from "netbank-shared/src/hooks";
import { tx } from "netbank-shared/src/libs/i18n";
import { PendingApplicationState } from "netbank-shared/src/libs/models/Content/Enums";
import { LoanTopUpDecisionError } from "./LoanTopUpDecisions/LoanTopUpDecisionError";
import { LoanTopUpDecisionSuccess } from "./LoanTopUpDecisions/LoanTopUpDecisionSuccess";
import { TrackingAction, TrackingCategory } from "netbank-shared/src/libs/models/Tracking";
import { toLocaleString } from "netbank-shared/src/libs/utils";
import { usePushInteraction } from "~hooks";

export interface LoanTopUpFinalStepProps {
  data: ILoanTopUpPage;
}

export const LoanTopUpFinalStep = observer(({ data }: LoanTopUpFinalStepProps) => {
  const history = useHistory();
  const { contentStore, offerStore, uiStore } = useStores();

  const { currentPendingApplication } = offerStore;

  const { locale, currency } = uiStore;

  usePushInteraction({
    eventCategory: TrackingCategory.ProductLoan,
    eventAction: TrackingAction.LoanRaise4,
    eventPrefix: toLocaleString(offerStore.totalLoanAmount, currency, locale, 0),
    eventValue: offerStore.totalLoanAmount,
  });

  const { finalStep, sharedContent } = data;

  if (
    currentPendingApplication?.applicationState === PendingApplicationState.Completed ||
    currentPendingApplication?.applicationState === PendingApplicationState.Disbursement
  ) {
    const routingPrefix = contentStore.getLoanWebRoutingPrefixFromCurrentAccount(offerStore.currentAccount);
    const accountNumber = offerStore.currentAccount?.accountNumber;
    return (
      <LoanTopUpDecisionSuccess
        image={finalStep.successImage}
        header={finalStep.successHeader}
        topText={finalStep.successTopText}
        monthlyCostLabel={sharedContent.monthlyCostLabel}
        newLoanAmountLabel={sharedContent.newLoanAmountLabel}
        repaymentTimeLabel={sharedContent.repaymentTimeLabel}
        interestLabel={sharedContent.interestLabel}
        continueButtonText={finalStep.successButtonText}
        continueButtonAction={() => {
          history.push(`/${tx("routing.lang")}/${routingPrefix}/${accountNumber}`);
          offerStore.resetTopUp();
        }}
      />
    );
  }

  return (
    <LoanTopUpDecisionError
      header={finalStep.errorHeader}
      topText={finalStep.errorTopText}
      errorHeader={finalStep.errorInnerHeader}
      errorText={finalStep.errorInnerText}
      buttonText={finalStep.errorButtonText}
    />
  );
});
