import React, { useState } from "react";
import { observer } from "mobx-react";
import { v4 as uuidv4 } from "uuid";
import { toLocaleString } from "netbank-shared/src/libs/utils";
import {
  Colors,
  PendingApplicationApplicantRole,
  PendingApplicationState,
  PurposeOfLoan,
  TopUpFlowStep,
} from "netbank-shared/src/libs/models/Content/Enums";
import { ITopUpApplicantForm } from "netbank-shared/src/libs/models/Offer/Offer";
import { ILoanAccount } from "netbank-shared/src/libs/models/Engagements";
import { ILoanTopUpPage } from "netbank-shared/src/libs/models/Content/Page";
import { useStores } from "netbank-shared/src/hooks";
import { tx } from "netbank-shared/src/libs/i18n";
import offerStyles from "~views/pages/Offers/Offers.scss";
import rootStyles from "~views/pages/Root.scss";
import styles from "../LoanTopUpFlow.scss";
import { Button } from "~views/shared";
import { HtmlContent } from "~views/shared/HtmlContent/HtmlContent";
import { ISummaryRow, Summary } from "~views/shared/Summary/Summary";
import { TrackingAction, TrackingCategory } from "netbank-shared/src/libs/models/Tracking";
import { useHistory, useLocation } from "react-router-dom";
import { usePushInteraction } from "~hooks";
import { Checkbox } from "~views/shared/Checkbox/Checkbox";
import errorIcon from "~assets/error.svg";

export interface LoanTopUpApplicantDetailsSummaryStepProps {
  data: ILoanTopUpPage;
  setNextTopUpStep: () => void;
  setPreviousTopUpStep: () => void;
}

export const LoanTopUpApplicantDetailsSummaryStep = observer(
  ({ data, setNextTopUpStep, setPreviousTopUpStep }: LoanTopUpApplicantDetailsSummaryStepProps) => {
    const { offerStore, uiStore } = useStores();

    const history = useHistory();
    const location = useLocation();

    const { currency, locale, isMobile } = uiStore;

    usePushInteraction({
      eventCategory: TrackingCategory.ProductLoan,
      eventAction: TrackingAction.LoanRaise2Summary,
      eventPrefix: toLocaleString(offerStore.totalLoanAmount, currency, locale, 0),
      eventValue: offerStore.totalLoanAmount,
    });

    const [loading, setLoading] = useState(false);

    const { hasCoApplicant, coApplicantName } = offerStore.currentAccount as ILoanAccount;

    const { applicantDetailsSummaryStep, sharedContent } = data;

    if (!offerStore.currentAccount) {
      return null;
    }

    const summaryRows: ISummaryRow[] = [
      {
        cols: [
          {
            label: `${sharedContent.monthlyCostLabel || tx("topUp.monthlyCostLabel")}:`,
            value: toLocaleString(offerStore.topUpMonthlyCost, currency, locale, 0),
            key: uuidv4(),
          },
          {
            label: `${sharedContent.newLoanAmountLabel || tx("topUp.newLoanAmountLabel")}:`,
            value: toLocaleString(
              Math.abs(offerStore.currentAccount.bookedBalance || 0) + offerStore.topUpAmount,
              currency,
              locale
            ),
            key: uuidv4(),
          },
        ],
        key: uuidv4(),
      },
      {
        cols: [
          {
            label: `${sharedContent.repaymentTimeLabel || tx("topUp.repaymentTimeLabel")}:`,
            value: `${offerStore.topUpRepaymentTime} ${tx("misc.years")}`,
            key: uuidv4(),
          },
        ],
        key: uuidv4(),
      },
    ];

    const renderApplicantSummary = (keyPrefix: string, applicant: ITopUpApplicantForm) => {
      const showEmployer = offerStore.showEmployer(applicant);

      let purposeOfLoan = offerStore.purposeOfLoan.value;

      if (purposeOfLoan === PurposeOfLoan.Other) {
        purposeOfLoan = offerStore.customPurposeOfLoan.value;
      }

      return (
        <>
          <h2>{applicantDetailsSummaryStep[`${keyPrefix}SummaryHeader`] || tx(`topUp.${keyPrefix}SummaryHeader`)}</h2>
          <div className={styles.applicantSummary}>
            <div>
              <p className={rootStyles.bold}>{`${sharedContent.emailLabel || tx("topUp.emailLabel")}:`}</p>
              <p>{applicant.email.value}</p>
            </div>
            <div>
              <p className={rootStyles.bold}>{`${sharedContent.phoneLabel || tx("topUp.phoneLabel")}:`}</p>
              <p>{applicant.phone.value}</p>
            </div>
            {keyPrefix === "mainApplicant" && (
              <div>
                <p className={rootStyles.bold}>{sharedContent.purposeOfLoanLabel || tx("topUp.purposeOfLoanLabel")}</p>
                <p>{purposeOfLoan}</p>
              </div>
            )}
            <div>
              <p className={rootStyles.bold}>
                {`${applicantDetailsSummaryStep[`${keyPrefix}MonthlyIncomeLabel`] || tx("topUp.monthlyIncomeLabel")}:`}
              </p>
              <p>{toLocaleString(applicant.monthlyIncome.value, currency, locale, 0)}</p>
            </div>
            <div>
              <p className={rootStyles.bold}>
                {`${
                  applicantDetailsSummaryStep[`${keyPrefix}EmploymentTypeLabel`] || tx("topUp.employmentStatusLabel")
                }:`}
              </p>
              <p>{applicant.employmentType.value}</p>
            </div>
            {showEmployer && (
              <div>
                <p className={rootStyles.bold}>
                  {`${applicantDetailsSummaryStep[`${keyPrefix}EmployerLabel`] || tx("topUp.employerLabel")}:`}
                </p>
                <p>{applicant.employer.value}</p>
              </div>
            )}
            {keyPrefix === "coApplicant" && (
              <div>
                <p className={rootStyles.bold}>
                  {sharedContent.sharedAccomodationLabel || tx("topUp.sharedAccomodationLabel")}
                </p>
                <p>{offerStore.applicantsShareLivingCost.value ? tx("misc.yes") : tx("misc.no")}</p>
              </div>
            )}

            {(keyPrefix === "mainApplicant" || !offerStore.applicantsShareLivingCost.value) && (
              <>
                <div>
                  <p className={rootStyles.bold}>
                    {`${sharedContent.housingTypeLabel || tx("topUp.housingTypeLabel")}:`}
                  </p>
                  <p>{applicant.housingType.value}</p>
                </div>
                <div>
                  <p className={rootStyles.bold}>
                    {`${sharedContent.housingCostLabel || tx("topUp.housingCostLabel")}:`}
                  </p>
                  <p>{toLocaleString(applicant.housingCost.value, currency, locale, 0)}</p>
                </div>
                <div>
                  <p className={rootStyles.bold}>
                    {`${sharedContent.totalSizeOfAllDebtLabel || tx("topUp.totalSizeOfAllDebtLabel")}:`}
                  </p>
                  <p>{toLocaleString(applicant.totalSizeOfAllDebt.value, currency, locale, 0)}</p>
                </div>
                <div>
                  <p className={rootStyles.bold}>
                    {`${sharedContent.totalCostOfAllDebtLabel || tx("topUp.totalCostOfAllDebtLabel")}:`}
                  </p>
                  <p>{toLocaleString(applicant.totalCostOfAllDebt.value, currency, locale, 0)}</p>
                </div>
                <div>
                  <p className={rootStyles.bold}>
                    {`${
                      applicantDetailsSummaryStep[`${keyPrefix}NumberOfChildrenLabel`] ||
                      tx("topUp.numberOfChildrenLabel")
                    }:`}
                  </p>
                  <p>{applicant.numberOfChildren.value}</p>
                </div>
              </>
            )}
            {keyPrefix === "mainApplicant" && offerStore.payoutAccount && (
              <div>
                <p className={rootStyles.bold}>
                  {sharedContent.payoutAccountLabel || `${tx("topUp.payoutAccountLabel")}:`}
                </p>
                <p>{`${offerStore.payoutAccount.clearingNumber}-${offerStore.payoutAccount.accountNumber}`}</p>
              </div>
            )}
          </div>
        </>
      );
    };

    const addOrUpdateTopUp = async () => {
      if (!offerStore.hasApprovedTopUpCreditCheck) {
        offerStore.showTopUpValidationErrors = true;
        return;
      }

      setLoading(true);

      if (offerStore.currentOffer) {
        const success = await offerStore.addTopUp();
        if (!success) {
          // Topup validation error
          window.scrollTo(0, 0);
          offerStore.currentTopUpStep = 3;
          offerStore.setTopUpFlowStep(TopUpFlowStep.DecisionStep);
          setLoading(false);
          return;
        }

        if (offerStore.addTopUpResponse?.applicationId) {
          offerStore.shouldResetTopup = false;
          // Redirect from offerId query param to applicationId after topup application is created
          const applicationParams = new URLSearchParams({
            applicationId: offerStore.addTopUpResponse.applicationId,
          });
          history.replace({
            pathname: location.pathname,
            search: applicationParams.toString(),
          });
        }
      } else if (
        offerStore.currentPendingApplication?.applicationState === PendingApplicationState.CoParticipantApproval &&
        offerStore.currentPendingApplication.applicantRole === PendingApplicationApplicantRole.CoCustomer
      ) {
        await offerStore.updateApplication();
      }
      setLoading(false);
      setNextTopUpStep();
    };

    return (
      <div>
        {applicantDetailsSummaryStep.header && (
          <HtmlContent html={`<h1>${applicantDetailsSummaryStep.header || tx("topUp.header")}</h1>`} />
        )}
        {applicantDetailsSummaryStep.text && (
          <HtmlContent className={styles.topText} html={applicantDetailsSummaryStep.text} />
        )}

        <Summary rows={summaryRows} color={Colors.Sunglow} />

        {renderApplicantSummary("mainApplicant", offerStore.mainTopupApplicantData)}

        {hasCoApplicant && coApplicantName && renderApplicantSummary("coApplicant", offerStore.coTopupApplicantData)}
        <Checkbox
          wrapperStyle={{ marginBottom: isMobile ? 24 : 35 }}
          label={tx("topUp.approveCreditCheckLabel")}
          checkboxId="applicant-credit-check"
          checked={offerStore.hasApprovedTopUpCreditCheck}
          disabled={loading}
          onChange={(e) => {
            offerStore.hasApprovedTopUpCreditCheck = e.target.checked;
            offerStore.showTopUpValidationErrors = false;
          }}
        />
        {offerStore.showTopUpValidationErrors && !offerStore.hasApprovedTopUpCreditCheck && (
          <div className={[rootStyles.error, styles.error].join(" ")}>
            <img src={errorIcon} alt="error-icon" />
            <span>{tx("error.required")}</span>
          </div>
        )}
        <div>
          <div className={offerStyles.submit}>
            <Button
              title={applicantDetailsSummaryStep.previousButtonText || tx("misc.back")}
              disabled={loading}
              onClick={setPreviousTopUpStep}
              borderColor="black"
              bordered
              large
            />
            <Button
              title={applicantDetailsSummaryStep.continueButtonText || tx("misc.continue")}
              color="red"
              loading={loading}
              onClick={addOrUpdateTopUp}
              large
            />
          </div>
        </div>
      </div>
    );
  }
);
