import React from "react";
import { Redirect, Route, RouteProps, Switch, withRouter } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { tx } from "netbank-shared/src/libs/i18n";

import { Home, Cms, Leasing, Card, Deposit, Login, SecuredLoan, PrivateLoan, Loan } from "./pages";

export const RouterWrapper = withRouter(({ location }: RouteProps) => {
  const stripTrailingSlash = () => {
    if (!location) return "";
    return `${location.pathname.slice(0, -1)}${location.search}${location.hash}`;
  };

  return (
    <TransitionGroup className="transition-group">
      <CSSTransition key={location?.key} timeout={{ enter: 300 }} classNames="fade">
        <div className="route-section">
          <Switch location={location}>
            <Redirect from="/:url*(/+)" to={stripTrailingSlash()} />
            <Route exact path="/">
              <Redirect to={`/${tx("routing.lang")}`} />
            </Route>
            <Route exact path={`/${tx("routing.lang")}/login`} component={Login} />
            <Route exact path={`/${tx("routing.lang")}`} component={Home} />
            <Route exact path={`/${tx("routing.lang")}/${tx("routing.loan")}/:loanId`} component={Loan} />
            <Route exact path={`/${tx("routing.lang")}/${tx("routing.securedLoan")}/:loanId`} component={SecuredLoan} />
            <Route exact path={`/${tx("routing.lang")}/${tx("routing.privateLoan")}/:loanId`} component={PrivateLoan} />
            <Route exact path={`/${tx("routing.lang")}/${tx("routing.leasing")}/:leasingId`} component={Leasing} />
            <Route exact path={`/${tx("routing.lang")}/${tx("routing.card")}/:cardId`} component={Card} />
            <Route exact path={`/${tx("routing.lang")}/${tx("routing.deposit")}/:depositId`} component={Deposit} />
            <Route default component={Cms} />
          </Switch>
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
});

export default RouterWrapper;
