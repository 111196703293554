import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { useStores } from "netbank-shared/src/hooks";
import { INewMessagePage } from "netbank-shared/src/libs/models/Content/Page";
import { AccountType, Colors, Product } from "netbank-shared/src/libs/models/Content/Enums";
import { IAccount } from "netbank-shared/src/libs/models/Engagements/Account";
import { Button, Dropdown } from "~views/shared";
import { TextArea } from "~views/shared/TextArea/TextArea";
import { tx } from "netbank-shared/src/libs/i18n";
import styles from "./NewMessage.scss";
import rootStyles from "../Root.scss";
import { ContentArea } from "~views/shared/ContentArea/ContentArea";
import { FaqBlock } from "~views/shared/ContentArea/Blocks/FaqBlock";
import { ListItem } from "netbank-shared/src/libs/models/Content/ListItem";
import { MessageEmptyState } from "./MessageEmptyState";
import { HtmlContent } from "~views/shared/HtmlContent/HtmlContent";
import { TrackingAction, TrackingCategory } from "netbank-shared/src/libs/models/Tracking";

export const NewMessage = observer(() => {
  const { messageStore, contentStore, uiStore, rootStore } = useStores();
  const page = contentStore.currentPage as INewMessagePage;
  const history = useHistory();

  useEffect(() => {
    return () => messageStore.resetStore();
  }, []);

  const handleCancel = () => {
    history.push(page?.messageInboxPage ?? `/${tx("routing.lang")}`);
    messageStore.resetStore();
  };

  const [invalidProduct, setInvalidProduct] = useState(false);
  const [invalidSubject, setInvalidSubject] = useState(false);
  const [invalidArea, setInvalidArea] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState(false);

  const selectAccountText = page.selectAccountText ?? tx("message.selectAccountText");
  const selectSubjectText = page.selectSubjectText ?? tx("message.selectSubjectText");
  const selectAreaText = page.selectAreaText ?? tx("message.selectAreaText");

  const formatAccountsForList = (_accounts: IAccount[]) => {
    return _accounts.map((acc, index) => ({
      index,
      label: `${acc.name || acc.nameIB} - ${acc.displayNumber}`,
      value: acc.accountNumber,
      extra: {
        key: acc.accountType,
      },
    }));
  };

  const products = page?.productDropdown;
  const { accounts } = rootStore;
  const accountList: ListItem[] = [];
  if (accounts.creditAccounts && products.some((p) => p.product === Product.Credit)) {
    accountList.push(...formatAccountsForList([...accounts.creditAccounts, ...accounts.blockedCreditAccounts]));
  }

  if (accounts.depositAccounts && products.some((p) => p.product === Product.Deposit)) {
    accountList.push(...formatAccountsForList(accounts.depositAccounts));
  }

  if (accounts.privateLoanAccounts && products.some((p) => p.product === Product.ConsumerLoan)) {
    accountList.push(
      ...formatAccountsForList([...accounts.privateLoanAccounts, ...accounts.blockedPrivateLoanAccounts])
    );
  }

  if (accounts.securedLoanAccounts && products.some((p) => p.product === Product.CarLoan)) {
    accountList.push(
      ...formatAccountsForList([...accounts.securedLoanAccounts, ...accounts.blockedSecuredLoanAccounts])
    );
  }

  if (accounts.leasingAccounts && products.some((p) => p.product === Product.PrivateLeasing)) {
    accountList.push(...formatAccountsForList(accounts.leasingAccounts));
  }

  // Get product based on selected account type
  const product = products.find((p) => {
    if (messageStore.account?.tag === AccountType.SecuredLoan) {
      return p.product === Product.CarLoan;
    }
    if (messageStore.account?.tag === AccountType.PrivateLoan) {
      return p.product === Product.ConsumerLoan;
    }
    return p.product === messageStore.account?.tag;
  });

  // Get subjects from product
  const subjects: ListItem[] = [];
  if (product?.subjectDropdown) {
    subjects.push(
      ...product.subjectDropdown
        .filter((sub) => subjects.length === 0 || !subjects.some((s) => s.value === sub.subject))
        .map((sub, index) => {
          return {
            index,
            label: `${sub.text}`,
            value: `${sub.subject}`,
            extra: {
              tags: sub.areaDropdown,
              showTextarea: sub.showTextarea,
              sendable: sub.sendable,
            },
          };
        })
    );
  }

  // Get areas from subject
  const areas: ListItem[] = [];
  if (messageStore.subject?.areas) {
    areas.push(
      ...messageStore.subject.areas.map((area, index) => ({
        index,
        label: `${area.text}`,
        value: `${area.area}`,
        extra: {
          showTextarea: area.showTextarea,
          sendable: area.sendable,
        },
      }))
    );
  }

  // Filter FAQ's based on selected product, subject and area
  const rawFaqs = page.faqBlocks || [];
  const productFaqs = rawFaqs.filter((faq) => {
    const faqProductTags = faq.product?.split(",");
    return faqProductTags?.some((tag) => tag === messageStore.account?.tag);
  });
  const faqs = productFaqs.filter((faq) => {
    const faqSubjectTags = faq.subject?.split(",");
    const faqAreaTags = faq.area?.split(",");
    const subjectFilterMatch =
      !!messageStore.subject && faqSubjectTags?.some((tag) => tag === messageStore.subject?.tag);
    const areaFilterMatch = !!messageStore.area && faqAreaTags?.some((tag) => tag === messageStore.area?.tag);
    const filterMatch = subjectFilterMatch || areaFilterMatch;
    return filterMatch;
  });

  const showTextarea = product?.showTextarea || messageStore.subject?.showTextarea || messageStore.area?.showTextarea;

  const sendable = product?.sendable || messageStore?.subject?.sendable || messageStore.area?.sendable;

  const handleSubmit = async () => {
    const { subject, area, newMessageBody } = messageStore;

    let validInput = true;

    if (!product) {
      setInvalidProduct(true);
      validInput = false;
    }
    if (!subject) {
      setInvalidSubject(true);
      validInput = false;
    }
    if (subject?.areas && subject.areas.length > 0 && !area) {
      setInvalidArea(true);
      validInput = false;
    }
    if (showTextarea && !newMessageBody) {
      setInvalidMessage(true);
      validInput = false;
    }
    if (validInput) {
      await messageStore.submit();
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      {messageStore.newMessageSuccess && <MessageEmptyState data={page.happyCaseEmptyState} />}
      {messageStore.newMessageSuccess === false && <MessageEmptyState data={page.errorCaseEmptyState} />}
      {messageStore.newMessageSuccess === undefined && (
        <>
          {page.pageDescription && (
            <section className={styles.pageDescription}>
              <HtmlContent html={page.pageDescription} />
            </section>
          )}
          <section className={uiStore.isMobile ? rootStyles.white : ""}>
            <div className={styles.newMessageWrapper}>
              <div className={styles.dropdownSection}>
                <div className={styles.dropdownWrapper}>
                  <Dropdown
                    list={accountList}
                    onChange={(item) => {
                      messageStore.setAccount(item);
                      setInvalidProduct(false);
                      setInvalidArea(false);
                    }}
                    fontWeight="regular"
                    value={messageStore.account !== undefined ? messageStore.account.label : selectAccountText}
                    label={page.accountLabelText ?? tx("message.accountLabelText")}
                    innerLabel={selectAccountText}
                    error={(invalidProduct && tx("message.productError")) || undefined}
                  />
                </div>
                <div className={styles.dropdownWrapper}>
                  <Dropdown
                    list={subjects}
                    onChange={(item) => {
                      messageStore.setSubject(item);
                      setInvalidSubject(false);
                    }}
                    disabled={messageStore.subjectDropdownDisabled}
                    fontWeight="regular"
                    value={messageStore.subject !== undefined ? messageStore.subject.label : selectSubjectText}
                    label={page.subjectLabelText ?? tx("message.subjectLabelText")}
                    innerLabel={selectSubjectText}
                    error={(invalidSubject && tx("message.subjectError")) || undefined}
                  />
                </div>
              </div>
              <div className={styles.dropdownSection}>
                <div className={styles.dropdownWrapper}>
                  {areas && areas.length > 0 && (
                    <Dropdown
                      list={areas || []}
                      onChange={(item) => {
                        messageStore.setArea(item);
                        setInvalidArea(false);
                      }}
                      fontWeight="regular"
                      value={messageStore.area !== undefined ? messageStore.area.label : selectAreaText}
                      label={page.areaLabelText ?? tx("message.areaLabelText")}
                      innerLabel={selectAreaText}
                      error={(invalidArea && tx("message.areaError")) || undefined}
                    />
                  )}
                </div>
              </div>
              {faqs.length > 0 && (
                <div className={styles.faqWrapper}>
                  <FaqBlock
                    label={page.faqSectionHeaderText}
                    faqBlocks={faqs}
                    color={Colors.BlackSqueeze}
                    noHorizontalPadding
                    noBorder
                  />
                </div>
              )}
              {showTextarea && (
                <TextArea
                  label={page.newMessageText ?? tx("message.newMessage")}
                  placeholder={page.textareaPlaceholder ?? tx("message.textareaPlaceholder")}
                  onChange={(e) => {
                    messageStore.setNewMessageBody(e.target.value);
                    setInvalidMessage(!e.target.value);
                  }}
                  error={(invalidMessage && tx("message.messageError")) || undefined}
                />
              )}
              <div className={styles.buttonWrapper}>
                <div className={styles.buttonActionsWrapper}>
                  <Button
                    title={page.cancelText ?? tx("misc.cancel")}
                    bordered
                    centered
                    borderColor="grey"
                    onClick={() => handleCancel()}
                  />
                  {sendable && (
                    <Button
                      title={page.sendMessageText ?? tx("message.sendMessage")}
                      bordered
                      centered
                      color="red"
                      borderColor="grey"
                      onClick={() => handleSubmit()}
                      loading={messageStore.sendingMessage}
                      trackingCategory={TrackingCategory.Messages}
                      trackingAction={TrackingAction.MessageCTA}
                    />
                  )}
                </div>
              </div>
            </div>
          </section>
          <ContentArea />
        </>
      )}
    </>
  );
});
