export interface IEngagementsCustomerResponse {
  customer: IEngagementsCustomer;
}

export interface IEngagementsCustomer {
  customerId: string;
  name: string;
  surName: string;
  mobile: string;
  phone: string;
  email: string;
  address: EngagementsAddress;
  notifications?: {
    sms: boolean;
    email: boolean;
    internetbank: boolean;
  };
  parties?: IParty[];
  isPartnerCustomer: boolean;
}

interface EngagementsAddress {
  addressLine1: string;
  addressLine2: string;
  zipCode: string;
  city: string;
  country: string;
}

export interface IParty {
  displayName: string;
  identityNumber: string;
  isOrganization: boolean;
  displayIdentityNumber: string;
}

export enum CustomerContactInfoField {
  Email = "Email",
  MobilePhone = "MobilePhone",
  HomePhone = "HomePhone",
  StreetAddress = "StreetAddress",
  PostCode = "PostCode",
  City = "City",
  Country = "Country",
}

export interface UpdateCustomerInfoData {
  homePhone?: string;
  mobilePhone?: string;
  emailAddress?: string;
}

export enum UpdateCustomerInfoStatus {
  UpdateSuccessfull = "UpdateSuccessfull",
  UpdateFailed = "UpdateFailed",
  ValidationError = "ValidationError",
}

export interface InputField {
  value?: string;
  editable?: boolean;
}
