import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory, useLocation } from "react-router-dom";
import { ILoanTopUpPage } from "netbank-shared/src/libs/models/Content/Page";
import { useStores, useTracking } from "netbank-shared/src/hooks";
import { tx } from "netbank-shared/src/libs/i18n";
import {
  Environment,
  PageType,
  PendingApplicationApplicantRole,
  PendingApplicationState,
  TopUpFlowStep,
} from "netbank-shared/src/libs/models/Content/Enums";
import { LoanTopUpDecisionSuccess } from "./LoanTopUpDecisions/LoanTopUpDecisionSuccess";
import { LoanTopUpDecisionError } from "./LoanTopUpDecisions/LoanTopUpDecisionError";
import { TrackingAction, TrackingCategory } from "netbank-shared/src/libs/models/Tracking";

export interface LoanTopUpDecisionStepProps {
  data: ILoanTopUpPage;
}

export const LoanTopUpDecisionStep = observer(({ data }: LoanTopUpDecisionStepProps) => {
  const { offerStore, contentStore, globalPersistentStore, rootStore, customerPersistentStore } = useStores();

  const dataLayer = useTracking((window as any).dataLayer);

  const location = useLocation();

  useEffect(() => {
    const page = contentStore.currentPage as ILoanTopUpPage;

    const pageContentTypeLength = page?.contentType?.length ?? 0;
    const pageType = page?.contentType?.[pageContentTypeLength - 1];

    const isTopUpPage =
      pageType === PageType.LoanTopUpPage && location.pathname === page.loanTopUpPage.replace(/\/$/, "");

    // Weird bug caused by navigating between different CmsPages (Loan Transit Page & Loan Top Up Page)
    // Verbose check is required to avoid firing LoanRaise3 tracking action twice
    if (isTopUpPage) {
      dataLayer.pushInteraction(TrackingCategory.ProductLoan, TrackingAction.LoanRaise3);
      offerStore.shouldResetTopup = true;
    }
  }, []);

  const history = useHistory();

  const { currentPendingApplication, canFinalizeTopUp } = offerStore;

  const { addTopUpSuccessResults, addTopUpErrorResults, sharedContent } = data;

  const defaultError = addTopUpErrorResults?.find(
    (r) => r.applicationState === PendingApplicationState.UnexpectedError
  );

  if (currentPendingApplication) {
    if (canFinalizeTopUp) {
      const success = addTopUpSuccessResults?.find(
        (r) => r.applicationState === currentPendingApplication.applicationState?.toString()
      );

      const routingPrefix = contentStore.getLoanWebRoutingPrefixFromCurrentAccount(offerStore.currentAccount);

      return (
        <LoanTopUpDecisionSuccess
          image={success?.image}
          header={success?.header}
          topText={success?.topText}
          monthlyCostLabel={sharedContent.monthlyCostLabel}
          newLoanAmountLabel={sharedContent.newLoanAmountLabel}
          repaymentTimeLabel={sharedContent.repaymentTimeLabel}
          interestLabel={sharedContent.interestLabel}
          cancelButtonText={success?.cancelButtonText}
          cancelButtonAction={() => {
            dataLayer.pushInteraction(TrackingCategory.ProductLoan, TrackingAction.LoanCancel);
            history.push(`/${tx("routing.lang")}/${routingPrefix}/${offerStore.currentAccount?.accountNumber}`);
            offerStore.resetTopUp();
          }}
          continueButtonText={success?.continueButtonText}
          continueButtonAction={() => {
            if (
              currentPendingApplication.applicationState === PendingApplicationState.ReadyForSigning &&
              currentPendingApplication.signUrl
            ) {
              if (globalPersistentStore.isMock && rootStore.config?.OCTOPUS_ENV !== Environment.Production) {
                // In mock, poll once to skip the signing process
                offerStore.setCurrentPendingApplicationByApplicationId(currentPendingApplication.applicationId);
                return;
              }
              customerPersistentStore.setSigningApplicationId(currentPendingApplication.applicationId);
              window.location.href = currentPendingApplication.signUrl;
              return;
            }
            if (currentPendingApplication.applicantRole === PendingApplicationApplicantRole.Customer) {
              offerStore.setTopUpFlowStep(
                currentPendingApplication.isPpiEligible
                  ? TopUpFlowStep.InsuranceStep
                  : TopUpFlowStep.PayoutSelectionStep
              );
            } else {
              offerStore.setTopUpFlowStep(TopUpFlowStep.EBAStep);
            }
            window.scrollTo(0, 0);
          }}
          continueButtonLoading={offerStore.loadingPendingApplication}
          faqs={success?.faqs}
        />
      );
    }

    const error = addTopUpErrorResults?.find(
      (r) => r.applicationState === currentPendingApplication.applicationState?.toString()
    );

    if (error) {
      return (
        <LoanTopUpDecisionError
          image={error.image}
          header={error.header}
          topText={error.topText}
          errorHeader={error.errorHeader}
          errorText={error.errorText}
          buttonText={error.closeButtonText}
          faqs={error.faqs}
        />
      );
    }
  }

  // Default to error if the status code cannot be found
  return (
    <LoanTopUpDecisionError
      image={defaultError?.image}
      header={defaultError?.header}
      topText={defaultError?.topText}
      errorHeader={defaultError?.errorHeader}
      errorText={defaultError?.errorText}
      buttonText={defaultError?.closeButtonText}
      faqs={defaultError?.faqs}
    />
  );
});
