import { ApiResponse, ApisauceInstance, create } from "apisauce";
import { AxiosAdapter, AxiosInstance } from "axios";
import MockAdapter from "axios-mock-adapter";
import { IAttachmentResponse } from "../models/Attachment/Attachment";
import { IConfig } from "../models/Config";
import { DocumentType, Environment, Limits } from "../models/Content/Enums";
import { IEngagementsCustomerResponse } from "../models/Customer/EngagementsCustomer";
import {
  BlockCardReason,
  IAccountTransactions,
  ICreditEngagements,
  IDepositEngagements,
  IDocument,
  IEngagements,
  IEvryToken,
  IGetDocumentsReponse,
  IGetTransactionsRequest,
  ILeaseEngagements,
  ILoanEngagements,
  ISingleCreditEngagement,
  OrderCardReason,
  IDataPortabilityDocument,
  ITransactionDocumentRequest,
  ITransactionDocument,
} from "../models/Engagements";
import { IAccountInvoices } from "../models/Engagements/Invoice";
import { IGetTerminationAmountRequest, ITerminationAmount } from "../models/Engagements/TerminationAmount";
import { INewMessage, INewMessageRequest, IReplyRequest, IReplyResponse } from "../models/Message/NewMessage";
import { IThread, IThreads } from "../models/Message/Threads";
import { Store, CustomerPersistentStore } from "../../stores";
import {
  mockCreditAccounts,
  mockBusinessCustomerData,
  mockDocuments,
  mockEngagements,
  mockInvoicesIndex,
  mockInvoicesSecond,
  mockTransactionsIndex,
  mockTransactionsSecond,
  mockDataPortability,
  mockLoanTransactions,
  mockDepositTransactions,
} from "./mock/engagements";
import { mockLetters, mockYearlyStatements } from "./mock/engagements/letters";
import { mockTerminationAmount } from "./mock/engagements/terminationAmount";
import { mockAttachments } from "./mock/messages/attachments";
import { mockThreads } from "./mock/messages/threads";
import { mockInsuranceLetters } from "./mock/engagements/letters/insuranceLetters";

export class EngagementsApi {
  rootStore: Store;

  customerPersistentStore: CustomerPersistentStore;

  client?: ApisauceInstance;

  mock?: MockAdapter;

  mockAdapter?: AxiosAdapter;

  actualAdapter?: AxiosAdapter;

  constructor(rootStore: Store, customerPersistentStore: CustomerPersistentStore) {
    this.rootStore = rootStore;
    this.customerPersistentStore = customerPersistentStore;
  }

  init = (config: IConfig | undefined) => {
    if (config) {
      const { platformStore } = this.rootStore;
      const platformSpecificHeaders = platformStore?.getPlatformSpecificHeaders();

      const headers: { [key: string]: string | number } = {
        "Content-Type": "application/json",
        "api-version": "1.0",
        "Ocp-Apim-Subscription-Key": config.OCP_APIM_SUBSCRIPTION_KEY,
      };
      if (platformSpecificHeaders) Object.assign(headers, platformSpecificHeaders);

      this.client = create({
        baseURL: config.ENGAGEMENTS_URL,
        headers,
        timeout: 60000,
      });

      this.client.addMonitor(this.handleResponse);

      if (config.OCTOPUS_ENV !== Environment.Production) this.setupMockAdapter();
    }
  };

  handleResponse = async (response: ApiResponse<any>) => {
    // Remove token and redirect to login page on 401
    if (response.status === 401 || !this.rootStore.securePersistentStore.accessToken) {
      await this.rootStore?.logout();
    }
  };

  getHeaders = (headers?: any) => ({
    headers: {
      ...headers,
      Authorization: `Bearer ${this.rootStore.securePersistentStore.accessToken}`,
    },
  });

  getCustomer = async (refresh: boolean) =>
    this.client?.get<IEngagementsCustomerResponse>(
      "/Customers/customer",
      { v: "2.0" },
      this.getHeaders(refresh ? { "api-version": "2.0", "Cache-Control": "no-cache" } : { "api-version": "2.0" })
    );

  getEngagements = async (refresh: boolean) => {
    const { currentOrganizationNumber } = this.customerPersistentStore;
    const identityNumberParams = currentOrganizationNumber ? { identityNumber: currentOrganizationNumber } : {};
    return this.client?.get<IEngagements>(
      "",
      {
        v: "1.0",
        ...identityNumberParams,
      },
      this.getHeaders(refresh ? { "Cache-Control": "no-cache" } : undefined)
    );
  };

  getCreditAccounts = async () =>
    this.client?.get<ICreditEngagements>("creditAccounts", { v: "1.0" }, this.getHeaders());

  getCreditAccount = async (id: string) =>
    this.client?.get<ISingleCreditEngagement>(`creditAccounts/${id}`, { v: "1.0" }, this.getHeaders());

  getDepositAccounts = async () =>
    this.client?.get<IDepositEngagements>("depositAccounts", { v: "1.0" }, this.getHeaders());

  getLeaseAccounts = async () => this.client?.get<ILeaseEngagements>("leaseAccounts", { v: "1.0" }, this.getHeaders());

  getPrivateLoanAccounts = async () =>
    this.client?.get<ILoanEngagements>("privateLoanAccounts", { v: "1.0" }, this.getHeaders());

  getSecuredLoanAccounts = async () =>
    this.client?.get<ILoanEngagements>("securedLoanAccounts", { v: "1.0" }, this.getHeaders());

  getTransactions = async ({
    accountNumber,
    nextToken,
    amountFrom,
    amountTo,
    dateFrom,
    dateTo,
  }: IGetTransactionsRequest) => {
    return this.client?.get<IAccountTransactions>(
      `transactions`,
      {
        accountNumber,
        nextToken,
        amountFrom,
        amountTo,
        dateFrom,
        dateTo,
        v: "1.0",
      },
      this.getHeaders({})
    );
  };

  getAccountInvoices = async (accountNumber: string, offset?: number, limit: number = Limits.invoice) =>
    this.client?.get<IAccountInvoices>(`invoices`, { accountNumber, offset, limit, v: "1.0" }, this.getHeaders());

  getInvoicePdf = async (accountNumber: string, documentId: string) =>
    /* eslint-disable-next-line no-undef */
    this.client?.get<BlobPart>(
      `invoicePdfs`,
      { accountNumber, documentId, v: "1.0" },
      {
        responseType: "blob",
        ...this.getHeaders({ Accept: "application/pdf" }),
      }
    );

  getShowPinToken = async () => this.client?.get<IEvryToken>("/Cards/ShowPinToken", { v: "1.0" }, this.getHeaders());

  getShowCardToken = async () => this.client?.get<IEvryToken>("/Cards/ShowCardToken", { v: "1.0" }, this.getHeaders());

  getThreads = async () => this.client?.get<IThreads>("/Threads", { v: "1.0" }, this.getHeaders());

  getThread = async (threadId: string) =>
    this.client?.get<IThread>(`/Threads/${threadId}`, { v: "1.0" }, this.getHeaders());

  getAttachment = async (threadId: string, attachmentId: string) =>
    this.client?.get<IAttachmentResponse>(
      `/Threads/${threadId}/attachments/${attachmentId}`,
      { v: "1.0" },
      this.getHeaders()
    );

  createThread = async (params: INewMessageRequest) => {
    const threadFormData = new FormData();

    threadFormData.append("v", "1.0");
    threadFormData.append("title", params.title);
    threadFormData.append("body", params.body);
    threadFormData.append("accountNumber", params.accountNumber);
    threadFormData.append("subjectTag", params.subjectTag);
    threadFormData.append("areaTag", params.areaTag);

    return this.client?.post<INewMessage>(
      `/Threads`,
      threadFormData,
      this.getHeaders({ "Content-Type": "multipart/form-data" })
    );
  };

  markThreadAsRead = async (threadId: string) =>
    this.client?.put(
      `/Threads/${threadId}`,
      {
        v: "1.0",
        customerAccessed: true,
      },
      this.getHeaders()
    );

  archiveThread = async (threadId: string) =>
    this.client?.put(
      `/Threads/${threadId}`,
      {
        v: "1.0",
        archivedByCustomer: true,
      },
      this.getHeaders()
    );

  createReplyMessage = async (params: IReplyRequest) => {
    const messageFormData = new FormData();

    messageFormData.append("v", "1.0");
    messageFormData.append("body", params.body);

    return this.client?.post<IReplyResponse>(
      `Threads/${params.threadId}/messages`,
      messageFormData,
      this.getHeaders({ "Content-Type": "multipart/form-data" })
    );
  };

  activateCard = async (cardId: string) =>
    this.client?.put<boolean>("/Cards/activatecard?v=1.0", { cardId }, this.getHeaders());

  hardblockCard = async (cardId: string, blockCardReqReason?: BlockCardReason) =>
    this.client?.put<boolean>("/Cards/hardblockcard?v=1.0", { cardId, blockCardReqReason }, this.getHeaders());

  orderCard = async (accountNumber: string, reason?: OrderCardReason) =>
    this.client?.post<boolean>("/Cards/ordercard?v=1.0", { accountNumber, reason }, this.getHeaders());

  getDocuments = async (accountNumber?: string, documentType?: DocumentType) =>
    this.client?.get<IGetDocumentsReponse>(`/Documents`, { accountNumber, documentType, v: "1.0" }, this.getHeaders());

  getDocument = async (documentId: string) =>
    this.client?.get<IDocument>(`/Documents/${documentId}`, { v: "1.0" }, this.getHeaders());

  getTransactionDocument = async ({ accountNumber, fromDate, toDate }: ITransactionDocumentRequest) =>
    this.client?.get<ITransactionDocument>(
      `/Documents`,
      {
        accountNumber,
        toDate,
        fromDate,
        v: "1.0",
        documentType: DocumentType.Transactions,
      },
      this.getHeaders()
    );

  getDataPortabilityDocument = async () =>
    this.client?.get<IDataPortabilityDocument>(
      `/DataPortabilityDocuments/${DocumentType.DataPortability}`,
      { v: "1.0" },
      this.getHeaders()
    );

  getTerminationAmount = async (data: IGetTerminationAmountRequest) => {
    const { accountNumber, terminationDate } = data;
    return this.client?.get<ITerminationAmount>(
      `/${accountNumber}/terminationAmounts`,
      { terminationDate, v: "1.0" },
      this.getHeaders()
    );
  };

  saveAccountName = async (data: any) => {
    return this.client?.post<boolean>("/saveAccountName?v=1.0", data, this.getHeaders());
  };

  updateContactInfo = async (updatedData: { mobileNumber: any; phoneNumber: any; email: any }) => {
    return this.client?.put<IEngagementsCustomerResponse>(
      "/customers/updateCustomer?v=1.0",
      updatedData,
      this.getHeaders()
    );
  };

  setupMockAdapter = () => {
    this.mock = new MockAdapter(this.client?.axiosInstance as AxiosInstance, {
      delayResponse: process.env.NODE_ENV === "test" ? 0 : 2000,
    });

    this.mockAdapter = this.client?.axiosInstance.defaults.adapter;

    // Defaults to real apis
    this.mock.restore();

    this.actualAdapter = this.client?.axiosInstance.defaults.adapter;

    const invoiceParams = (accountNumber: string, offset = 0, limit = Limits.invoice) => ({
      params: {
        accountNumber,
        offset,
        limit,
        v: "1.0",
      },
    });

    const transactionsParams = (accountNumber: string, nextToken = "") => ({
      params: {
        accountNumber,
        nextToken,
        amountFrom: "",
        amountTo: "",
        dateFrom: "",
        dateTo: "",
        v: "1.0",
      },
    });

    this.mock.onGet("/Customers/customer").reply(200, mockBusinessCustomerData);

    this.mock.onGet("").reply(200, mockEngagements);

    this.mock.onGet("creditAccounts").reply(200, mockCreditAccounts);

    this.mock.onGet("transactions", transactionsParams("23151256593")).reply(200, mockTransactionsIndex);

    this.mock
      .onGet("transactions", transactionsParams("23151256593", "e095cb78b6e983cf"))
      .reply(200, mockTransactionsSecond);

    this.mock.onGet("transactions", transactionsParams("33125380791")).reply(200, mockTransactionsIndex);

    this.mock
      .onGet("transactions", transactionsParams("33125380791", "e095cb78b6e983cf"))
      .reply(200, mockTransactionsSecond);

    this.mock.onGet("transactions", transactionsParams("93530002743298")).reply(200, mockTransactionsIndex);

    this.mock
      .onGet("transactions", transactionsParams("93530002743298", "e095cb78b6e983cf"))
      .reply(200, mockTransactionsSecond);

    this.mock.onGet("transactions", transactionsParams("628302")).reply(200, mockLoanTransactions);

    this.mock.onGet("transactions", transactionsParams("653405")).reply(200, mockLoanTransactions);

    this.mock.onGet("transactions", transactionsParams("442326")).reply(200, mockDepositTransactions);

    this.mock.onGet("transactions", transactionsParams("1675062")).reply(200, mockDepositTransactions);

    this.mock.onGet("invoices", invoiceParams("23151256593")).reply(200, mockInvoicesIndex);

    this.mock.onGet("invoices", invoiceParams("23151256593", Limits.invoice)).reply(200, mockInvoicesSecond);

    this.mock.onGet("invoices", invoiceParams("33125380791")).reply(200, mockInvoicesIndex);

    this.mock.onGet("invoices", invoiceParams("33125380791", Limits.invoice)).reply(200, mockInvoicesSecond);

    this.mock.onGet("invoices", invoiceParams("93530002743298")).reply(200, mockInvoicesIndex);

    this.mock.onGet("invoices", invoiceParams("93530002743298", 20)).reply(200, mockInvoicesSecond);

    this.mock.onGet("invoices", invoiceParams("653405")).reply(200, mockInvoicesIndex);

    this.mock.onGet("invoices", invoiceParams("653405", Limits.invoice)).reply(200, mockInvoicesSecond);

    this.mock.onGet("invoices", invoiceParams("681617")).reply(200, mockInvoicesIndex);

    this.mock.onGet("invoices", invoiceParams("681617", Limits.invoice)).reply(200, mockInvoicesSecond);

    this.mock.onGet("invoices", invoiceParams("669158")).reply(200, mockInvoicesIndex);

    this.mock.onGet("invoices", invoiceParams("669158", Limits.invoice)).reply(200, mockInvoicesSecond);

    this.mock.onGet("invoicePdfs").reply(async () => {
      const doc = await this.rootStore.commonService.createPdf();
      return [200, doc];
    });

    this.mock.onGet(`DataPortabilityDocuments/${DocumentType.DataPortability}`).reply(200, mockDataPortability);

    this.mock.onPut("/Cards/activatecard?v=1.0").reply(204);

    this.mock.onPut("/Cards/hardblockcard?v=1.0").reply(200, true);

    this.mock.onPost("/Cards/ordercard?v=1.0").reply((config) => {
      const data = JSON.parse(config.data);
      const { reason, accountNumber } = data;

      let success = false;
      if (mockEngagements?.creditAccounts) {
        if (reason === OrderCardReason.Order) {
          success =
            mockEngagements?.creditAccounts?.findIndex(
              (a) =>
                a.accountNumber === accountNumber &&
                a?.canOrderCard &&
                (!a.cards || a?.cards.findIndex((c) => !c.isVirtual) < 0)
            ) >= 0;
        } else if (reason !== OrderCardReason.Order && Object.values(OrderCardReason).includes(reason)) {
          success =
            mockEngagements?.creditAccounts?.findIndex(
              (a) =>
                a.accountNumber === accountNumber &&
                a?.canOrderCard &&
                a.cards &&
                a.cards?.findIndex((c) => !c.isVirtual) >= 0
            ) >= 0;
        }
      }
      return [success ? 200 : 400, success];
    });

    const idRegexSegment = "[a-zA-Z0-9]*";

    this.mock.onGet(`/Threads`).reply(200, mockThreads);

    this.mock.onGet(new RegExp(`/Threads/${idRegexSegment}/attachments/${idRegexSegment}`)).reply((config) => {
      if (config.url) {
        const urlArray = config.url.split("/");
        const attachmentId = urlArray[urlArray.length - 1];
        const attachment = mockAttachments.find((a) => a.id === attachmentId);
        if (attachment) {
          return [200, attachment];
        }
        return [404];
      }
      return [500];
    });

    this.mock.onGet(new RegExp(`/Threads/${idRegexSegment}`)).reply((config) => {
      if (config.url) {
        const urlArray = config.url.split("/");
        const threadId = urlArray[urlArray.length - 1];
        return [200, mockThreads.threads.find((t) => t.threadId === threadId)];
      }
      return [500];
    });

    this.mock.onPost("/Threads").reply(200, mockThreads.threads[0]);

    this.mock
      .onPost(new RegExp(`/Threads/${idRegexSegment}/messages`))
      .reply(200, mockThreads.threads[0].messages[0].messageId);

    this.mock.onPatch(new RegExp(`/Threads/${idRegexSegment}`)).reply(204);

    this.mock
      .onGet("/Documents", {
        params: {
          accountNumber: "23151256593",
          documentType: DocumentType.Contract,
          v: "1.0",
        },
      })
      .reply(200, mockDocuments);

    this.mock
      .onGet("/Documents", {
        params: {
          accountNumber: "681617",
          documentType: DocumentType.Contract,
          v: "1.0",
        },
      })
      .reply(200, mockDocuments);

    this.mock
      .onGet("/Documents", {
        params: {
          accountNumber: "442326",
          documentType: DocumentType.Contract,
          v: "1.0",
        },
      })
      .reply(200, mockDocuments);

    this.mock
      .onGet("/Documents", {
        params: {
          accountNumber: "443739",
          documentType: DocumentType.Contract,
          v: "1.0",
        },
      })
      .reply(200, mockDocuments);

    this.mock
      .onGet("/Documents", {
        params: {
          accountNumber: "669158",
          documentType: DocumentType.Contract,
          v: "1.0",
        },
      })
      .reply(200, mockDocuments);

    this.mock
      .onGet("/Documents", {
        params: {
          accountNumber: "",
          documentType: DocumentType.YearlyStatement,
          v: "1.0",
        },
      })
      .reply(200, mockYearlyStatements);

    this.mock
      .onGet("/Documents", {
        params: {
          accountNumber: "",
          documentType: DocumentType.Letter,
          v: "1.0",
        },
      })
      .reply(200, mockLetters);

    this.mock
      .onGet("/Documents", {
        params: {
          accountNumber: "",
          documentType: DocumentType.InsuranceLetter,
          v: "1.0",
        },
      })
      .reply(200, mockInsuranceLetters);

    this.mock
      .onGet(new RegExp(`/Documents/${idRegexSegment}`), {
        params: { v: "1.0" },
      })
      .reply((config) => {
        if (config.url) {
          const urlArray = config.url.split("/");
          const documentId = urlArray[urlArray.length - 1];
          const document = mockDocuments.documents.find((t) => t.id === documentId);

          if (!document) return [404];

          // const doc = new jsPDF();

          // doc.text("Mock contract", 10, 10);
          // const output = doc.output();

          const response: IDocument = {
            mimeType: document.mimeType,
            fileName: document.fileName,
            fileBytes: btoa(""),
          };
          return [200, response];
        }
        return [500];
      });

    this.mock.onGet(new RegExp(`/${idRegexSegment}/terminationAmounts`), {}).reply(200, mockTerminationAmount);

    this.mock.onPost("/saveAccountName?v=1.0").reply(200, true);

    this.mock.onPut("customers/updateCustomer?v=1.0").reply(200, { ok: true });
  };

  setMock = (isMock: boolean) => {
    if (this.client) {
      if (isMock) {
        this.client.axiosInstance.defaults.adapter = this.mockAdapter;
      } else {
        this.client.axiosInstance.defaults.adapter = this.actualAdapter;
      }
    }
  };
}
