import dayjs from "dayjs";
import { LocaleUtils } from "react-day-picker";
import { Lang } from "../models/Content/Enums";

// Must match react-day-picker LocaleUtils type...
type Months = [string, string, string, string, string, string, string, string, string, string, string, string];

const WEEKDAYS_LONG: KeyObject<string[]> = {
  en: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
  sv: ["Söndag", "Måndag", "Tisdag", "Onsdag", "Torsdag", "Fredag", "Lördag"],
};
const WEEKDAYS_SHORT: KeyObject<string[]> = {
  en: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
  sv: ["Sön", "Mån", "Tis", "Ons", "Tors", "Fre", "Lör"],
};
const MONTHS: KeyObject<Months> = {
  en: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  sv: [
    "Januari",
    "Februari",
    "Mars",
    "April",
    "Maj",
    "Juni",
    "Juli",
    "Augusti",
    "September",
    "Oktober",
    "November",
    "December",
  ],
};

const FIRST_DAY: KeyObject<number> = {
  en: 0,
  sv: 1, // Use Monday as first day of the week
};

const formatDate = (d: Date, format?: string): string => {
  return dayjs(d).format(format);
};

const formatDay = (d: Date, locale?: string): string => {
  const l = locale || Lang.sv;
  return `${WEEKDAYS_LONG[l][d.getDay()]}, ${d.getDate()} ${MONTHS[l][d.getMonth()]} ${d.getFullYear()}`;
};

const formatMonthTitle = (d: Date, locale?: string): string => {
  const l = locale || Lang.sv;
  return `${MONTHS[l][d.getMonth()]} ${d.getFullYear()}`;
};

const formatWeekdayShort = (i: number, locale?: string): string => {
  const l = locale || Lang.sv;
  return WEEKDAYS_SHORT[l][i];
};

const formatWeekdayLong = (i: number, locale?: string): string => {
  const l = locale || Lang.sv;
  return WEEKDAYS_SHORT[l][i];
};

const getFirstDayOfWeek = (locale?: string): number => {
  const l = locale || Lang.sv;
  return FIRST_DAY[l];
};

const getMonths = (locale?: string): Months => {
  const l = locale || Lang.sv;
  return MONTHS[l];
};

const parseDate = (str: string, format?: string): Date => {
  return dayjs(str, format).toDate();
};

export const dateLocaleUtils: LocaleUtils = {
  formatDate,
  formatDay,
  formatMonthTitle,
  formatWeekdayShort,
  formatWeekdayLong,
  getFirstDayOfWeek,
  getMonths,
  parseDate,
};
