import React from "react";
import { NavbarElementProps } from "react-day-picker";
import styles from "./DateRange.scss";
import chevronRight from "~assets/chevron-right.svg";

type INavbarProps = NavbarElementProps & {
  disabled?: boolean;
};

export const Navbar = ({
  month,
  nextMonth,
  previousMonth,
  onPreviousClick,
  onNextClick,
  localeUtils,
  disabled,
}: INavbarProps) => {
  const months = localeUtils.getMonths();
  const prev = months[previousMonth.getMonth()];
  const next = months[nextMonth.getMonth()];
  const currentMonth = months[month.getMonth()];
  const currentYear = month.getFullYear();
  return (
    <div className={styles.dateNavbar}>
      {!disabled && (
        <div className={styles.prev} onClick={() => onPreviousClick()}>
          <img src={chevronRight} alt="prev" />
          <span>{prev.slice(0, 3)}</span>
        </div>
      )}
      <div className={styles.current}>
        {currentMonth} {currentYear}
      </div>
      {!disabled && (
        <div className={styles.next} onClick={() => onNextClick()}>
          <span>{next.slice(0, 3)}</span>
          <img src={chevronRight} alt="next" />
        </div>
      )}
    </div>
  );
};
