import { ApiResponse, ApisauceInstance, create } from "apisauce";
import { AxiosAdapter, AxiosInstance } from "axios";
import MockAdapter from "axios-mock-adapter";
import { IConfig } from "libs/models/Config";
import {
  IComplianceStatus,
  IComplianceDefinition,
  IUpdateComplianceAnswer,
  IComplianceUpdateResponse,
  IComplianceSigningStatus,
  IUploadAttachmentsResponse,
} from "libs/models/CustomerCompliance/Compliance";
import { CustomerPersistentStore, Store } from "stores";
import { ComplianceLanguage, Environment, ISOCode } from "../models/Content/Enums";
import { MockCustomerComplianceApi } from "./mock/MockCustomerComplianceApi";

export class CustomerComplianceApi {
  rootStore: Store;

  customerPersistentStore: CustomerPersistentStore;

  client?: ApisauceInstance;

  mock?: MockAdapter;

  mockAdapter?: AxiosAdapter;

  actualAdapter?: AxiosAdapter;

  isMock?: Boolean;

  constructor(rootStore: Store, customerPersistentStore: CustomerPersistentStore) {
    this.rootStore = rootStore;
    this.customerPersistentStore = customerPersistentStore;
  }

  init = (config: IConfig | undefined) => {
    if (config) {
      const headers: { [key: string]: string | number } = {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": config.CUSTOMER_COMPLIANCE_OCP_APIM_SUBSCRIPTION_KEY,
        "x-country": ISOCode.SE,
        "x-language": ComplianceLanguage.SWE,
      };

      this.client = create({
        baseURL: config.CUSTOMER_COMPLIANCE_URL,
        headers,
        timeout: 60000,
      });

      this.client.addMonitor(this.handleResponse);

      if (config.OCTOPUS_ENV !== Environment.Production) this.setupMockAdapter();
    }
  };

  handleResponse = async (response: ApiResponse<any>) => {
    // Remove token and redirect to login page on 401
    if (response.status === 401 || !this.rootStore.securePersistentStore.accessToken) {
      await this.rootStore?.logout();
    }
  };

  getHeaders = (headers?: any) => ({
    headers: {
      ...headers,
      Authorization: `Bearer ${this.rootStore.securePersistentStore.accessToken}`,
    },
  });

  getStatuses = async () => {
    const { currentOrganizationNumber } = this.customerPersistentStore;
    const identityNumberParams = currentOrganizationNumber ? { identityNumber: currentOrganizationNumber } : {};
    return this.client?.get<IComplianceStatus>("statuses", { ...identityNumberParams }, this.getHeaders());
  };

  getDefinitions = async () => {
    const { currentOrganizationNumber } = this.customerPersistentStore;
    const identityNumberParams = currentOrganizationNumber ? { identityNumber: currentOrganizationNumber } : {};
    return this.client?.get<IComplianceDefinition[]>(
      `questionnaire-definitions`,
      { ...identityNumberParams },
      this.getHeaders()
    );
  };

  updateAnswers = async (answerId: string, updateRequest: IUpdateComplianceAnswer[]) => {
    const { currentOrganizationNumber } = this.customerPersistentStore;
    const identityNumberQuery = currentOrganizationNumber ? `?identityNumber=${currentOrganizationNumber}` : "";
    return this.client?.put<IComplianceUpdateResponse>(
      `questionnaire-answers/${answerId}${identityNumberQuery}`,
      updateRequest,
      this.getHeaders()
    );
  };

  uploadAttachments = async (answerId: string, questionId: string, request: FormData) => {
    const { currentOrganizationNumber } = this.customerPersistentStore;
    let query = `?questionId=${questionId}`;
    if (currentOrganizationNumber) {
      query += `&identityNumber=${currentOrganizationNumber}`;
    }
    return this.client?.post<IUploadAttachmentsResponse>(
      `questionnaire-answers/${answerId}/attachments${query}`,
      request,
      this.getHeaders({ "content-type": "multipart/form-data" })
    );
  };

  deleteAttachment = async (answerId: string, questionId: string, attachmentId: string) => {
    const { currentOrganizationNumber } = this.customerPersistentStore;
    let query = `?questionId=${questionId}`;
    if (currentOrganizationNumber) {
      query += `&identityNumber=${currentOrganizationNumber}`;
    }
    return this.client?.delete(
      `questionnaire-answers/${answerId}/attachments/${attachmentId}${query}`,
      undefined,
      this.getHeaders()
    );
  };

  getSignatures = async () => {
    const { currentOrganizationNumber } = this.customerPersistentStore;
    const identityNumberParams = currentOrganizationNumber ? { identityNumber: currentOrganizationNumber } : {};
    return this.client?.get<IComplianceSigningStatus>(`signatures`, { ...identityNumberParams }, this.getHeaders());
  };

  setupMockAdapter = () => {
    this.mock = new MockAdapter(this.client?.axiosInstance as AxiosInstance, {
      delayResponse: process.env.NODE_ENV === "test" ? 0 : 2000,
    });

    this.mockAdapter = this.client?.axiosInstance.defaults.adapter as AxiosAdapter;

    // Defaults to real apis
    this.mock.restore();

    this.actualAdapter = this.client?.axiosInstance.defaults.adapter as AxiosAdapter;

    MockCustomerComplianceApi(this.mock);
  };

  setMock = (isMock: boolean) => {
    if (this.client) {
      if (isMock) {
        this.client.axiosInstance.defaults.adapter = this.mockAdapter;
      } else {
        this.client.axiosInstance.defaults.adapter = this.actualAdapter;
      }
    }
  };
}
