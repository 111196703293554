import { useStores, useTracking } from "netbank-shared/src/hooks";
import styles from "./Login.scss";
import { tx } from "netbank-shared/src/libs/i18n";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { IGenericContentLink } from "netbank-shared/src/libs/models/Content/ContentLink";
import { IImage } from "netbank-shared/src/libs/models/Content/Image";
import santanderLogotype from "~assets/santander-logo.svg";
import { ProfilePicker } from "./ProfilePicker";

interface IProfilePickerPageProps {
  image?: IGenericContentLink<IImage>;
  data: { header: string; subHeader: string; privateProfileLabel: string; organizationProfileLabel: string };
}

export const ProfilePickerPage = ({ image, data }: IProfilePickerPageProps) => {
  const history = useHistory();
  const dataLayer = useTracking((window as any).dataLayer);
  const { uiStore, contentStore, customerStore, customerPersistentStore } = useStores();
  const { currentPage } = contentStore;
  const santanderHomepageURL = currentPage?.santanderHomepageURL || tx("login.santanderHomepageURL");
  const parties = customerStore.currentCustomer?.parties;

  const logotype = uiStore.isMobile
    ? currentPage?.mobileLogotype?.url
    : currentPage?.logotypeLoginPage?.url || santanderLogotype;

  const setTermsAndRedirectToHome = () => {
    if (!customerPersistentStore.viewedTerms && contentStore.currentPage?.termsPage) {
      history.push(contentStore.currentPage.termsPage);
      dataLayer.pushPageView(contentStore.currentPage.termsPage);
    } else {
      history.push(`/${tx("routing.lang")}`);
      dataLayer.pushPageView(`/${tx("routing.lang")}`);
    }
  };

  const setProfile = (isOrganization: boolean, organizationNumber: string) => {
    customerPersistentStore.setIsLoggedInToCorporateAccount(isOrganization);
    if (isOrganization) {
      customerPersistentStore.setCurrentOrganizationNumber(organizationNumber);
    } else {
      customerPersistentStore.setCurrentOrganizationNumber(undefined);
    }
    setTermsAndRedirectToHome();
  };

  if (!parties) {
    setTermsAndRedirectToHome();
    return null;
  }

  return (
    <>
      <header className={`${styles.loginHeader} ${styles.flexBetween}`}>
        {logotype && (
          <a href={santanderHomepageURL}>
            <img className={styles.loginLogotype} src={logotype} alt="Santander" />
          </a>
        )}
      </header>
      <div className={styles.wrapper}>
        <div className={styles.image} style={{ backgroundImage: `url("${image?.url}")` }} />
        <div className={styles.overlay}>
          <ProfilePicker
            parties={parties}
            textContent={{
              header: data.header,
              subheader: data.subHeader,
              privateProfileLabel: data.privateProfileLabel,
              organizationProfileLabel: data.organizationProfileLabel,
            }}
            setProfile={setProfile}
          />
        </div>
      </div>
    </>
  );
};
