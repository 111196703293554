import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory, useParams } from "react-router-dom";
import { useStores, useTracking, useLogoAnimation } from "netbank-shared/src/hooks";
import { toLocaleString } from "netbank-shared/src/libs/utils";
import { IProductRouteParams } from "netbank-shared/src/libs/models/Routing";
import { NotificationLevel } from "netbank-shared/src/libs/models/Content/Notification";
import { tx } from "netbank-shared/src/libs/i18n";
import { withRoot } from "../Root";
import styles from "../Page.scss";
import accordionStyle from "~views/shared/ContentArea/Blocks/AccordionBlock.scss";
import { ContentArea } from "~views/shared/ContentArea/ContentArea";
import { NotFoundPage } from "../NotFound/NotFound";
import { Button, EmptyState, InfoText } from "~views/shared";
import { ProductPageSkeleton } from "../Skeletons/ProductPageSkeleton";
import { TrackingAction, TrackingCategory } from "netbank-shared/src/libs/models/Tracking";
import { IEmptyState } from "netbank-shared/src/libs/models/Content/EmptyState";
import { IPendingApplication } from "netbank-shared/src/libs/models/Offer/Offer";
import { PendingApplicationState } from "netbank-shared/src/libs/models/Content/Enums";
import { IContentAreaItem } from "netbank-shared/src/libs/models/Content/ContentAreaItem";
import { IModal } from "netbank-shared/src/libs/models/Content/Modal";
import editIcon from "~assets/pencil-outline.svg";
import { getAccountDisplayName } from "netbank-shared/src/libs/utils/accountName";
import { AccountStatus } from "netbank-shared/src/libs/models/Engagements/Account";
import { renderCloseDate, renderClosedAccountInfo } from "./SharedClosedLoan";

export interface LoanPageProps {
  location: Location;
}


const renderDisabledAccountContent = (blockedEmptyState: IEmptyState) => {
  return (
    <section>
      <div className={[accordionStyle.wrapper, accordionStyle.open].join(" ")}>
        <div className={accordionStyle.header}>{blockedEmptyState.header}</div>
        <div className={accordionStyle.content} style={{ maxHeight: 10000 }}>
          <div className={accordionStyle.verticalPadding}>
            <EmptyState data={blockedEmptyState} />
          </div>
        </div>
      </div>
    </section>
  );
};

const PrivateLoanPage = observer(({ location }: LoanPageProps) => {
  const history = useHistory();
  const { loanId } = useParams<IProductRouteParams>();
  const { contentStore, loanStore, uiStore, invoiceStore, offerStore } = useStores();
  const { currentAccountNumber, allLoanAccounts } = loanStore;

  const dataLayer = useTracking((window as any).dataLayer);

  const { currency, locale } = uiStore;
  const currentAccount = allLoanAccounts.find((account) => account.accountNumber === currentAccountNumber);
  const isDisabled = currentAccount?.isDisabled;

  const loanTemplate = contentStore.currentPrivateLoanTemplatePage;

  let pendingApplication: IPendingApplication | undefined;
  if (currentAccount) {
    pendingApplication = offerStore.getActivePendingApplicationByAccountNumber(currentAccount.displayNumber, true);
  }

  useEffect(() => {
    const fetchTemplate = async () => {
      if (loanStore.currentAccountNumber !== loanId) loanStore.currentAccountNumber = loanId;

      await contentStore.getPrivateLoanTemplate();

      if (!invoiceStore.fetchingInvoices) {
        invoiceStore.getAccountInvoices(loanId, true);
      }
    };
    fetchTemplate();

    return () => {
      invoiceStore.resetStore();
    };
  }, [location]);

  useLogoAnimation([uiStore.logoAnimationLoaded, loanStore?.loanAccounts]);

  useEffect(() => {
    if (loanId) {
      loanStore.setCurrentAccount(loanId);
    }
  }, [currentAccount, loanStore.loanAccounts, loanId, contentStore.privateLoanTemplatePages]);

  const renderModal = (modalContent?: IContentAreaItem[]) => {
    const modal: IModal = {
      content: modalContent || [],
    };

    uiStore.setModal(modal);
  };

  if (uiStore.loading) return null;

  if (contentStore.fetchingTemplatePage) {
    return <ProductPageSkeleton />;
  }

  if (!currentAccount) {
    return <NotFoundPage />;
  }

  return (
    <div className={styles.wrapper}>
      <section className={styles.headerWrapper}>
        {renderClosedAccountInfo(currentAccount, loanTemplate?.closedAccountInfo)}
        <div>
          <h1
            style={{
              display: "inline-flex",
            }}
          >
            {getAccountDisplayName(loanStore.currentAccount)}
          </h1>
          {contentStore.isSetAccountNameEnabled && currentAccount.accountStatus !== AccountStatus.AccountClosed && (
            <Button
              className={styles.boxWrapper}
              onClick={() => {
                renderModal(loanTemplate?.setAccountNameModalContent);
              }}
            >
              <div className={styles.box}>
                <img src={editIcon} alt="edit-icon" />
              </div>
            </Button>
          )}
          <p>
            <span className={styles.bold}>{loanTemplate?.subHeading || "N°:"}</span>
            {` ${currentAccount.displayNumber}`}
          </p>
          <p>
            <span className={styles.bold}>{tx("deposit.productType").concat(":")}</span>
            {` ${currentAccount.nameIB}`}
          </p>
          {renderCloseDate(currentAccount, loanTemplate?.closedDateTitle)}
        </div>
      </section>
      {!isDisabled && (
        <section>
          <div className={styles.templateHeader}>
            {!uiStore.isMobile && (
              <div
                className={styles.image}
                style={{
                  backgroundImage: `url(${loanTemplate?.image?.url})`,
                }}
              />
            )}
            <div className={styles.templateContent}>
              <div className={styles.availableBalance}>
                <div>
                  <span className={styles.templateTitle}>{loanTemplate?.actualAmount}</span>
                  <h3>
                    {toLocaleString(
                      (currentAccount.bookedBalance || 0) === 0 ? 0 : currentAccount.bookedBalance! * -1,
                      currency,
                      locale
                    )}
                  </h3>
                </div>
                {uiStore.isMobile && (
                  <div
                    className={styles.image}
                    style={{
                      backgroundImage: `url(${loanTemplate?.image?.url})`,
                    }}
                  />
                )}
              </div>
              <div className={styles.flexBetween}>
                <div>
                  <span className={styles.templateTitle}>{loanTemplate?.interest || tx("misc.interest")}</span>
                  <h4>{`${toLocaleString(currentAccount.accountInterest || 0, undefined, locale, 2)} %`}</h4>
                </div>
                <div>
                  <span className={styles.templateTitle}>{loanTemplate?.totalAmount}</span>
                  <h4>{toLocaleString(currentAccount.creditLimit, currency, locale)}</h4>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {!isDisabled && pendingApplication?.applicationId && loanTemplate?.loanTopUpPage && (
        <>
          {pendingApplication.applicationState === PendingApplicationState.Disbursement && (
            <section>
              <InfoText
                header={
                  loanTemplate.pendingApplicationsDisbursementBannerHeader ||
                  tx("pendingApplications.disbursementBannerHeader")
                }
                text={
                  loanTemplate.pendingApplicationsDisbursementBannerContent ||
                  tx("pendingApplications.disbursementBannerContent")
                }
                level={NotificationLevel.important}
              />
            </section>
          )}

          {pendingApplication.applicationState !== PendingApplicationState.Disbursement && (
            <section>
              <InfoText
                header={loanTemplate.pendingApplicationsBannerHeader || tx("pendingApplications.bannerHeader")}
                text={loanTemplate.pendingApplicationsBannerContent || tx("pendingApplications.bannerContent")}
                buttonText={
                  loanTemplate.pendingApplicationsBannerButtonText || tx("pendingApplications.bannerButtonText")
                }
                buttonAction={() => {
                  dataLayer.pushInteraction(TrackingCategory.ProductLoan, TrackingAction.LoanResume);
                  history.push(`${loanTemplate.loanTopUpPage}?applicationId=${pendingApplication!.applicationId}`);
                }}
                level={NotificationLevel.important}
              />
            </section>
          )}
        </>
      )}
      {!isDisabled && <ContentArea page={loanTemplate} />}
      {isDisabled &&
        loanTemplate?.accountBlockedEmptyState?.[0] &&
        renderDisabledAccountContent(loanTemplate.accountBlockedEmptyState[0])}
    </div>
  );
});

export default withRoot(PrivateLoanPage);
