import { observer } from "mobx-react";
import { useStores } from "netbank-shared/src/hooks";
import { BankAccountControlFlowState, KreditzFlowMessages } from "netbank-shared/src/libs/models/Content/Enums";
import styles from "../BankAccountControlFlow.scss";
import React, { useEffect, useRef } from "react";
import IframeResizer from "iframe-resizer-react";
import { handleBankAccountControlEvent } from "netbank-shared/src/libs/utils/bankAccountControlFlow";

interface BankAccountControlKreditzInControlStepProps {
  setOverflowHidden: (value: boolean) => void;
}

export const BankAccountControlKreditzInControlStep = observer(
  ({ setOverflowHidden }: BankAccountControlKreditzInControlStepProps) => {
    const { uiStore, transferStore } = useStores();
    const { bankAccountControlPopup } = uiStore;
    const stepTransitionRef = useRef<boolean>();
    const kreditzState = useRef<string>(KreditzFlowMessages.BankSelection);
    useEffect(() => {
      const listener = (event: MessageEvent<string>) => {
        if (event.origin !== bankAccountControlPopup.origin || event.data.startsWith("[iFrameSizer]")) {
          return;
        }

        stepTransitionRef.current = true;
        setOverflowHidden(true);
        const currentState = handleBankAccountControlEvent(event.data, transferStore);
        kreditzState.current = currentState;
      };

      window.addEventListener("message", listener);
      return () => {
        window.removeEventListener("message", listener);
      };
    }, [setOverflowHidden]);

    return (
      <IframeResizer
        heightCalculationMethod="min"
        onLoad={() => uiStore.setBankAccountControlFlowState(BankAccountControlFlowState.KreditzInControl)}
        checkOrigin={[bankAccountControlPopup.origin]}
        className={styles.kreditzIFrame}
        onResized={(resizeEvent) => {
          /*
            The kreditz iframe has a part of it's sizing based on view height
            which means that with iframeresizer it will never shrink it can only get larger
            as a workaround every time we navigate to next step we reset the height to container height
            and reenable resizing once new step is loaded this fixes large size carrying over to next steps
          */
          if (resizeEvent.type === "init") {
            stepTransitionRef.current = false;
            setOverflowHidden(false);
          }

          /*
            Form step has minimum height without which button to continue is not visible but it's not working with resizer
          */
          if (kreditzState.current === KreditzFlowMessages.FormSubmitted) {
            // eslint-disable-next-line no-param-reassign
            resizeEvent.iframe.style.minHeight = "660px";
          }

          /*
            First step doesn't work with scrolling - so we have to manually resize it to container size
          */
          const resetHeightFistStep =
            resizeEvent.type === "resetPage" &&
            (kreditzState.current === KreditzFlowMessages.BankSelection ||
              kreditzState.current === KreditzFlowMessages.SelectedBankCancelled);

          if (resetHeightFistStep || stepTransitionRef.current) {
            // eslint-disable-next-line no-param-reassign
            resizeEvent.iframe.style.height = "";
          }
        }}
        tolerance={20}
        style={{
          display:
            uiStore.bankAccountControlflowState === BankAccountControlFlowState.IFrameDataLoaded ? "none" : "block",
        }}
        src={bankAccountControlPopup.source}
      />
    );
  }
);
