export abstract class CommonService {
  abstract handleLogout(url?: string, isInternal?: boolean): void;

  abstract getQueryParam(key: string): string;

  abstract redirect(url: string, params?: object): void;

  abstract redirectToLogin(): void;

  abstract createPdf(): BlobPart | Promise<BlobPart>;

  abstract getUserAgentHeader(): string;

  abstract redirectToBankId(bankIdUrl: string): Promise<void>;

  resetRoute?(name: string, params?: any): void;

  pop?(): boolean;

  getCurrentRouteName?(): string;

  getCurrentRouteParams?(): any;
}
