import React from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { useStores } from "netbank-shared/src/hooks";
import { IImage } from "netbank-shared/src/libs/models/Content/Image";
import { IFAQItem } from "netbank-shared/src/libs/models/Content/accordions/FAQItem";
import { IGenericContentLink } from "netbank-shared/src/libs/models/Content/ContentLink";
import { tx } from "netbank-shared/src/libs/i18n";
import styles from "../../LoanTopUpFlow.scss";
import offerStyles from "~views/pages/Offers/Offers.scss";
import { HtmlContent } from "~views/shared/HtmlContent/HtmlContent";
import { AccordionFaqBlock } from "~views/shared/ContentArea/Blocks/Accordions/AccordionFaqBlock";
import { Button } from "~views/shared";
import { TrackingAction, TrackingCategory } from "netbank-shared/src/libs/models/Tracking";
import { PendingApplicationState } from "netbank-shared/src/libs/models/Content/Enums";
import { usePushInteraction } from "~hooks";

export interface LoanTopUpDecisionErrorProps {
  image?: IGenericContentLink<IImage>;
  header?: string;
  topText?: string;
  errorHeader?: string;
  errorText?: string;
  buttonText?: string;
  faqs?: IGenericContentLink<IFAQItem>[];
}

export const LoanTopUpDecisionError = observer(
  ({ image, header, topText, errorHeader, errorText, buttonText, faqs }: LoanTopUpDecisionErrorProps) => {
    const { contentStore, offerStore } = useStores();
    const history = useHistory();

    const headerStyles = [styles.headerText];

    if (!image?.url) {
      headerStyles.push(styles.noMarginLeft);
    }

    usePushInteraction({
      eventCategory: TrackingCategory.ProductLoan,
      eventAction: TrackingAction.LoanRaiseError,
      eventPrefix: offerStore.currentPendingApplication?.applicationState ?? PendingApplicationState.UnexpectedError,
    });

    const routingPrefix = contentStore.getLoanWebRoutingPrefixFromCurrentAccount(offerStore.currentAccount);

    return (
      <div>
        <div className={[styles.decisionHeader, styles.error].join(" ")}>
          {image && <img src={image.url} alt={image.altText} />}
          <div className={headerStyles.join(" ")}>
            <HtmlContent html={`<h1>${header || tx("topUp.unsuccessfulTopUpHeader")}</h1>`} />
            {topText && <HtmlContent html={topText} />}
          </div>
        </div>
        {errorText && (
          <div className={styles.errorDetails}>
            {errorHeader && <h3>{errorHeader}</h3>}
            {errorText && <HtmlContent html={errorText} />}
          </div>
        )}
        {buttonText && (
          <div className={offerStyles.submit}>
            <Button
              title={buttonText}
              color="red"
              onClick={() =>
                history.push(`/${tx("routing.lang")}/${routingPrefix}/${offerStore.currentAccount?.accountNumber}`)
              }
              large
            />
          </div>
        )}
        {faqs && faqs.length > 0 && (
          <div className={styles.decisionFaqs}>
            {faqs.map((faqBlock) => (
              <AccordionFaqBlock key={faqBlock.contentLink.guidValue} data={faqBlock} />
            ))}
          </div>
        )}
      </div>
    );
  }
);
