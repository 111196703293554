import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import { BrowserRouter } from "react-router-dom";
import { configure } from "mobx";
import { RouterWrapper } from "./RouterWrapper";
import { getConfig } from "../config/config";

configure({ useProxies: "never", enforceActions: "never" });

const initializeGtm = async () => {
  const gtmId = (await getConfig())?.GTM_ID;
  if (gtmId && gtmId.indexOf("#") === -1) {
    TagManager.initialize({ gtmId });
  }
};

initializeGtm();

export const App = () => {
  return (
    <BrowserRouter>
      <RouterWrapper />
    </BrowserRouter>
  );
};

export default ReactDOM.render(<App />, document.querySelector("#root") as HTMLElement);
