import { create, ApisauceInstance } from "apisauce";
import { Store } from "stores";
import { IConfig } from "../models/Config";
import { ICustomer } from "../models/Customer/Customer";

export class CustomerApi {
  rootStore: Store;

  client?: ApisauceInstance;

  constructor(rootStore: Store) {
    this.rootStore = rootStore;
  }

  init = (config: IConfig | undefined) => {
    if (config) {
      const { platformStore } = this.rootStore;
      const platformSpecificHeaders = platformStore?.getPlatformSpecificHeaders();

      const headers: { [key: string]: string | number } = {
        "Content-Type": "application/json",
      };

      if (platformSpecificHeaders) Object.assign(headers, platformSpecificHeaders);

      this.client = create({
        baseURL: config.CUSTOMERS_API_URL,
        headers,
        timeout: 10000,
      });
    }
  };

  getDetails = async (customerId: string) => this.client?.get<ICustomer>(`details/${customerId}`);
}
