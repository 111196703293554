import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { initStore, Store } from "netbank-shared/src/stores/Store";
import { StoreContext } from "netbank-shared/src/contexts/storesContext";
import { PageLoader } from "~views/shared";
import { OutdatedBrowser } from "~views/pages/OutdatedBrowser/OutdatedBrowser";
import { getConfig } from "../config/config";
import { WebCommonService } from "./WebCommonService";
import WebPlatformStore from "./WebPlatformStore";

interface Props {
  history: RouteComponentProps["history"];
}

interface State {
  store: Store;
}

export const withStore = (Comp: any) =>
  class MobxApp extends React.Component<Props, State> {
    constructor(props: Props) {
      super(props);
      this.state = {
        store: initStore(localStorage, localStorage, new WebCommonService(props.history), new WebPlatformStore()),
      };
    }

    async componentDidMount() {
      const { store } = this.state;
      const { history } = this.props;
      const config = await getConfig();
      const mountedStore = await store.onMount(config, window, history);
      this.setState({ store: mountedStore });
    }

    render() {
      const { store } = this.state;

      if (!store.config) return null;

      return (
        <StoreContext.Provider value={store}>
          {store.uiStore.hasOutdatedBrowser ? (
            <OutdatedBrowser />
          ) : (
            <>
              <PageLoader />
              <Comp {...this.props} store={store} />
            </>
          )}
        </StoreContext.Provider>
      );
    }
  };
