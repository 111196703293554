import { ValidatorFuncProps, ValidatorOptionProps } from "./ValidatorProps";
import { tx } from "../i18n";

export const PhoneValidator: ValidatorFuncProps = (value: string, { errorName }: ValidatorOptionProps) => {
  const regex = /^[\d+\- ]*$/;
  let valid = true;
  let error = "";

  let minLength = 9;
  let maxLength = 15;

  let norm = value ? value.replace(/[^0-9+]/g, "") : "";
  if (norm.startsWith("00")) {
    norm = norm.replace("00", "+");
  }

  if (norm.startsWith("+")) {
    minLength += 3;
    maxLength += 3;
  }

  if (!value) {
    valid = false;
    error = tx("validators.required", {
      name: errorName,
    });
  } else if (!regex.test(value)) {
    valid = false;
    error = tx("validators.phone", {
      name: errorName,
    });
  }
  else if (norm.length < minLength || norm.length > maxLength) {
    valid = false;
    error = tx("validators.phone", {
      name: errorName,
    });
  }

  return { valid, error };
};

export const HomePhoneValidator: ValidatorFuncProps = (value: string, { errorName }: ValidatorOptionProps) => {
  let valid = true;
  let error = "";

  let minLength = 7;
  let maxLength = 11;

  let norm = value ? value.replace(/[^0-9+]/g, "") : "";
  if (norm.startsWith("00")) {
    norm = norm.replace("00", "+");
  }

  if (norm.startsWith("+")) {
    minLength += 3;
    maxLength += 3;
  }

  if (!value) {
    valid = false;
    error = tx("validators.required", {
      name: errorName,
    });
  } else if (norm.length < minLength || norm.length > maxLength) {
    valid = false;
    error = tx("validators.phone", {
      name: errorName,
    });
  }

  return { valid, error };
};
